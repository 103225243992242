import React from "react";
import styles from "./styles/CustomFecha.module.css";

const CustomFecha = ({ dia, mes, año }) => {

  return (
    <div id="containerFecha" className={styles.containerFecha}>
      <h2 className={styles.titleFecha} type="text">
        FECHA
      </h2>
      <div className={styles.subContainerNums}>
        {dia} / {mes} / {año}
      </div>
    </div>
  );
};

export default CustomFecha;
