import React from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../../actions";
import style from './styles/LoginButton.module.css';
import { useNavigate } from "react-router-dom";

const LoginButton = ({ formLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = () => {
    dispatch(loginUser(formLogin));
    
    navigate('/');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <button
        disabled={!formLogin.email || !formLogin.pass}
        onClick={() => handleLogin()}
        type="submit"
        className={style.buttonIngresar}
      >
        Ingresar
      </button>
    </div>
  );
};

export default LoginButton;
