import React, { useState, useEffect } from "react";
import { SideBar } from "../../SideBar/SideBar";
import { InputsReserva } from "./InputsReserva";
import DocumentoReserva from "./DocumentoReserva";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ReservaToPDF = ({ route }) => {
  const userState = useSelector((state) => state.asesor.state);
  const navigate = useNavigate();
  const [stateReserva, setStateReserva] = useState({
    nombre: "",
    dni: "",
    dia: "",
    mes: "",
    año: "",
    domicilio: "",
    garantia: "",
    alquiler: "",
    expensas: "",
    reserva: "",
    cuotas: "",
    monto: "",
    diaVig: "",
    mesVig: "",
    añoVig: "",
  });
  const [clicked, setClicked] = useState(false);
  const [cuit, setCuit] = useState(false);

  useEffect(() => {
    // if (!userState) {
    //   navigate("/login");
    // }
  }, []);

  const generarPDF = () => {
    const input = document.getElementById("contenido");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Reserva ${stateReserva.nombre}`);
    });
  };

  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <InputsReserva
          setStateReserva={setStateReserva}
          stateReserva={stateReserva}
          generarPDF={generarPDF}
          route={route}
          clicked={clicked}
          setClicked={setClicked}
          cuit={cuit}
          setCuit={setCuit}
        />
        <DocumentoReserva inputs={stateReserva} iva={clicked} cuit={cuit}/>
      </div>
    </div>
  );
};

export default ReservaToPDF;
