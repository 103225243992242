import React from "react";

//Contratos Comercio
import ComercioSinCobranteContado from "./Contratos/Comercio/SinCobrante/ComercioSinCobranteContado";
import ComercioSinCobranteCuotas from "./Contratos/Comercio/SinCobrante/ComercioSinCobranteCuotas";
import Comercio1CobranteContado from "./Contratos/Comercio/ConCobrante/Comercio1CobranteContado";
import Comercio1CobranteCuotas from "./Contratos/Comercio/ConCobrante/Comercio1CobranteCuotas";
import Comercio2CobrantesContado from "./Contratos/Comercio/ConCobrante/Comercio2CobrantesContado";
import Comercio2CobrantesCuotas from "./Contratos/Comercio/ConCobrante/Comercio2CobrantesCuotas";

//Contratos Vivienda
import ViviendaSinCobranteContado from "./Contratos/Vivienda/SinCobrante/ViviendaSinCobranteContado";
import ViviendaSinCobranteCuotas from "./Contratos/Vivienda/SinCobrante/ViviendaSinCobranteCuotas";
import Vivienda1CobranteContado from "./Contratos/Vivienda/ConCobrante/Vivienda1CobranteContado";
import Vivienda1CobranteCuotas from "./Contratos/Vivienda/ConCobrante/Vivienda1CobranteCuotas";
import Vivienda2CobrantesContado from "./Contratos/Vivienda/ConCobrante/Vivienda2CobrantesContado";
import Vivienda2CobrantesCuotas from "./Contratos/Vivienda/ConCobrante/Vivienda2CobrantesCuotas";

import CustomFecha from "../CustomFecha/CustomFecha";
import CustomFooter from "../CustomFooter/CustomFooter";

import { logoTrustPDF } from "./styles/assets";

import styles from "./styles/ContratoToPDF.module.css";

const DocumentoContrato = ({ props }) => {
  const { comercioVivienda, cobrante, cobrantes, contadoCuotas, formContrato } =
    props;

  return (
    <>
      <div id="item" className={styles.item}>
        <div id="recibo" className={styles.recibo}>
          <div id="containerTextura" className={styles.containerTextura}>
            <div id="containerLogo" className={styles.containerLogo}>
              <img src={logoTrustPDF} alt="Imagen" className={styles.imagen} />
            </div>
            <div id="containerText" className={styles.containerText}>
              <div id="sideTitle" className={styles.sideTitle}>
                <h2 className={styles.text}>
                  La garantía que <b>necesitás </b>
                  para vivir donde <b>querés.</b>
                </h2>
              </div>
            </div>
          </div>
          <div id="containerDatos" className={styles.containerDatos}>
            <div id="headerDatos" className={styles.headerDatos}>
              <div id="titleDatos" className={styles.titleDatos}>
                <h5 className={styles.recibo}>Contrato de Garantía</h5>
              </div>
              <CustomFecha
                dia={formContrato.diaInput}
                mes={formContrato.mesInput}
                año={formContrato.añoInput}
              />
            </div>

            <div className={styles.bodyContrato}>
              <div className={styles.contrato}>
                {comercioVivienda === "Comercio" &&
                  !cobrante &&
                  contadoCuotas === "Contado" && (
                    <ComercioSinCobranteContado props={props} />
                  )}

                {comercioVivienda === "Comercio" &&
                  !cobrante &&
                  contadoCuotas === "Cuotas" && (
                    <ComercioSinCobranteCuotas props={props} />
                  )}

                {comercioVivienda === "Vivienda" &&
                  !cobrante &&
                  contadoCuotas === "Contado" && (
                    <ViviendaSinCobranteContado props={props} />
                  )}

                {comercioVivienda === "Vivienda" &&
                  !cobrante &&
                  contadoCuotas === "Cuotas" && (
                    <ViviendaSinCobranteCuotas props={props} />
                  )}

                {comercioVivienda === "Comercio" &&
                  cobrante &&
                  contadoCuotas === "Contado" &&
                  cobrantes === 1 && <Comercio1CobranteContado props={props} />}

                {comercioVivienda === "Comercio" &&
                  cobrante &&
                  contadoCuotas === "Contado" &&
                  cobrantes === 2 && (
                    <Comercio2CobrantesContado props={props} />
                  )}

                {comercioVivienda === "Comercio" &&
                  cobrante &&
                  contadoCuotas === "Cuotas" &&
                  cobrantes === 1 && <Comercio1CobranteCuotas props={props} />}

                {comercioVivienda === "Comercio" &&
                  cobrante &&
                  contadoCuotas === "Cuotas" &&
                  cobrantes === 2 && <Comercio2CobrantesCuotas props={props} />}

                {comercioVivienda === "Vivienda" &&
                  cobrante &&
                  contadoCuotas === "Contado" &&
                  cobrantes === 1 && <Vivienda1CobranteContado props={props} />}

                {comercioVivienda === "Vivienda" &&
                  cobrante &&
                  contadoCuotas === "Contado" &&
                  cobrantes === 2 && (
                    <Vivienda2CobrantesContado props={props} />
                  )}

                {comercioVivienda === "Vivienda" &&
                  cobrante &&
                  contadoCuotas === "Cuotas" &&
                  cobrantes === 1 && <Vivienda1CobranteCuotas props={props} />}

                {comercioVivienda === "Vivienda" &&
                  cobrante &&
                  contadoCuotas === "Cuotas" &&
                  cobrantes === 2 && <Vivienda2CobrantesCuotas props={props} />}
              </div>
            </div>
          </div>
        </div>
        <CustomFooter />
      </div>
    </>
  );
};

export default DocumentoContrato;
