import React, { useEffect, useState } from "react";
import DatosAsesorSection from "./DatosAsesorSection";
import style from "./styles/ProfileAsesor.module.css";
import { SideBar } from "../SideBar/SideBar";
import ButtonLogOut from "./ButtonLogOut";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProfileAsesor = () => {
  const stateAsesor = useSelector((state) => state.asesor);
  const navigate = useNavigate();

  useEffect(() => {
    if (!stateAsesor.state) {
      navigate("/login");
    }
  }, []);

  return (
    <div style={{ display: "flex", width: "-webkit-fill-available" }}>
      <SideBar />
      <div className={style.ProfileContainer}>
        <DatosAsesorSection asesor={stateAsesor} />
        <ButtonLogOut data={stateAsesor.email} />
      </div>
    </div>
  );
};

export default ProfileAsesor;
