import React, { useState } from "react";
import style from "./styles/InputsRecibo.module.css";
import objectRecibos from "./objectRecibos";
import InputSection from "../CotizacionesToPDF/InputSection/InputSection";
import currencyMask from "../CotizacionesToPDF/Controllers/currencyMask";
import style2 from "../ReciboToPDF/styles/ReciboToPDF.module.css";

const InputsRecibos = ({
  handleChangeFormRecibo,
  stateRecibo,
  generarPDF,
  route,
}) => {
  const buttonsMoneda = ["Pesos", "Dolares"];

  const handleClickMoneda = (value) => {
    const obj = {
      target: {
        name: "moneda",
        value: value,
      },
    };
    handleChangeFormRecibo(obj);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "-webkit-fill-available",
        flexDirection: "column",
        // justifyContent: "space-between",
      }}
    >
      <div
        style={{ flexDirection: "column" }}
        className={style.ContainerGeneral}
      >
        {objectRecibos.map((section, index) => {
          return (
            <InputSection
              id={section.id}
              formCotizacion={stateRecibo}
              buttonsMoneda={buttonsMoneda}
              handleClickMoneda={handleClickMoneda}
              inputs={section.inputs}
              titleSection={section.titleSection}
              handleChangeFormCotizacion={handleChangeFormRecibo}
              currencyMask={currencyMask}
              route={route}
              key={index}
            />
          );
        })}
      </div>
      <button
        onClick={() => generarPDF(stateRecibo.nombreApellido, "contenido", route)}
        className={style2.buttonPDF}
      >
        Generar PDF
      </button>
    </div>
  );
};

export default InputsRecibos;
