import React, { useState } from "react";
import generarPDFContrato from "./generarPDFContrato";
import styles from "./styles/InputsContrato.module.css";
import currencyMask from "../CotizacionesToPDF/Controllers/currencyMask";
import objectContrato from "./objectInputsContrato/objectContrato";
import objectCuotas from "./objectInputsContrato/objectCuotas";
import object1Cogarante from "./objectInputsContrato/object1Cogarante";
import object2Cogarante from "./objectInputsContrato/object2Cogarante";
import InputSection from "../CotizacionesToPDF/InputSection/InputSection";

const InputsContrato = ({
  setFormContrato,
  formContrato,
  handleButton,
  buttonIzq,
  buttonDer,
  cobrante,
  cobrantes,
  contadoCuotas,
  nombreApellido,
  route,
  setLoading,
}) => {

  const handleChangeFormContrato = async (e) => {
    let { name, value } = e.target;

    if (
      name === "nroContrato" ||
      name === "dni" ||
      name === "garantia" ||
      name === "alquiler" ||
      name === "expensas"
    ) {
      let resultado = currencyMask(e);
      setFormContrato((prevFormContrato) => ({
        ...prevFormContrato,
        [name]: resultado.target.value,
      }));
    } else if (name === "porcentajePrimerCuota") {
      let restante = 100 - parseInt(value);
      setFormContrato((prevFormContrato) => ({
        ...prevFormContrato,
        [name]: value,
        porcentajeRestante: restante,
      }));
    } else {
      setFormContrato((prevFormContrato) => ({
        ...prevFormContrato,
        [name]: value,
      }));
    }
  };

  const handleClickGenerar = () => {
    setLoading(true);
    generarPDFContrato(nombreApellido, "contenido", setLoading);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "-webkit-fill-available",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "-webkit-fill-available",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="titleDatos" className={styles.titleDatos}>
            <h5 className={styles.recibo}>Contrato</h5>
          </div>
          <div className={styles.buttons}>
            <button
              value={buttonIzq}
              className={styles.buttonPDF}
              onClick={(e) => handleButton(e.target.value)}
            >
              {buttonIzq}
            </button>
            <button
              value={buttonDer}
              className={styles.buttonPDF}
              onClick={(e) => handleButton(e.target.value)}
            >
              {buttonDer}
            </button>
          </div>

          {objectContrato.map((contrato, index) => {
            return (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "30px" }}
                key={index}
              >
                <InputSection
                  id={contrato[0].id}
                  formCotizacion={formContrato}
                  inputs={contrato[0].inputs}
                  titleSection={contrato[0].titleSection}
                  handleChangeFormCotizacion={handleChangeFormContrato}
                  currencyMask={currencyMask}
                  route={route}
                />

                {contrato.length > 1 ? (
                  <InputSection
                    id={contrato[1].id}
                    formCotizacion={formContrato}
                    inputs={contrato[1].inputs}
                    titleSection={contrato[1].titleSection}
                    handleChangeFormCotizacion={handleChangeFormContrato}
                    currencyMask={currencyMask}
                    route={route}
                  />
                ) : null}
              </div>
            );
          })}

          {contadoCuotas === "Cuotas" &&
            objectCuotas.map((contrato, index) => {
              return (
                <div key={index}>
                  <InputSection
                    id={contrato[0].id}
                    formCotizacion={formContrato}
                    inputs={contrato[0].inputs}
                    titleSection={contrato[0].titleSection}
                    handleChangeFormCotizacion={handleChangeFormContrato}
                    currencyMask={currencyMask}
                    route={route}
                  />

                  {contrato.length > 1 && (
                    <InputSection
                      id={contrato[1].id}
                      formCotizacion={formContrato}
                      inputs={contrato[1].inputs}
                      titleSection={contrato[1].titleSection}
                      handleChangeFormCotizacion={handleChangeFormContrato}
                      currencyMask={currencyMask}
                      route={route}
                    />
                  )}
                </div>
              );
            })}

          {cobrantes === 1
            ? object1Cogarante.map((contrato, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                    }}
                    key={index}
                  >
                    <InputSection
                      id={contrato[0].id}
                      formCotizacion={formContrato}
                      inputs={contrato[0].inputs}
                      titleSection={contrato[0].titleSection}
                      handleChangeFormCotizacion={handleChangeFormContrato}
                      currencyMask={currencyMask}
                      route={route}
                    />

                    {contrato.length > 1 && (
                      <InputSection
                        id={contrato[1].id}
                        formCotizacion={formContrato}
                        inputs={contrato[1].inputs}
                        titleSection={contrato[1].titleSection}
                        handleChangeFormCotizacion={handleChangeFormContrato}
                        currencyMask={currencyMask}
                        route={route}
                      />
                    )}
                  </div>
                );
              })
            : cobrantes === 2 &&
              object2Cogarante.map((contrato, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                    }}
                    key={index}
                  >
                    <InputSection
                      id={contrato[0].id}
                      formCotizacion={formContrato}
                      inputs={contrato[0].inputs}
                      titleSection={contrato[0].titleSection}
                      handleChangeFormCotizacion={handleChangeFormContrato}
                      currencyMask={currencyMask}
                      route={route}
                    />

                    {contrato.length > 1 && (
                      <InputSection
                        id={contrato[1].id}
                        formCotizacion={formContrato}
                        inputs={contrato[1].inputs}
                        titleSection={contrato[1].titleSection}
                        handleChangeFormCotizacion={handleChangeFormContrato}
                        currencyMask={currencyMask}
                        route={route}
                      />
                    )}
                  </div>
                );
              })}

          <button
            onClick={() => handleClickGenerar()}
            className={styles.buttonPDF}
          >
            Generar PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputsContrato;
