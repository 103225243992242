import React, { useEffect, useState } from "react";
import { getInmosByAsesorTF } from "../../actions";
import style from "./styles/ProfileAsesor.module.css";
import { useDispatch, useSelector } from "react-redux";

const DatosAsesorSection = ({ asesor }) => {
  const { name, email, ventas } = asesor;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInmosByAsesorTF(name));
  }, []);

  const listInmobiliarias = useSelector((state) => state.inmosProfile);

  return (
    <div className={style.ContainerSection}>
      <div className={style.ContainerDatos}>
        <div className={style.RowDato}>
          Nombre y Apellido:
          <div className={style.Dato}>{name}</div>
        </div>
        <div className={style.RowDato}>
          Email:
          <div className={style.Dato}>{email}</div>
        </div>
      </div>
      <div className={style.ContainerDatos}>
        <div className={style.RowDato}>
          Total de ventas:
          <div className={style.Dato}>{ventas}</div>
        </div>
        <div style={{ flexDirection: "column" }} className={style.RowDato}>
          Lista de Inmobiliarias:
          <select className={style.slide}>
            {listInmobiliarias?.length ? (
              listInmobiliarias.map((element) => {
                return <option className={style.Dato}>{element.nombre}</option>;
              })
            ) : (
              <option>No hay Inmobiliarias asociadas a este Asesor</option>
            )}
          </select>
        </div>
      </div>
    </div>
  );
};

export default DatosAsesorSection;
