import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { flechaModal, flechaModalBlue } from "../styles/assets";
import style from "./styles/CustomCuota.module.css";

const CustomCuota = ({
  activeItem,
  setActiveItem,
  tipo,
  cardTitle,
  subTitleCard,
  valorAnticipo3,
  valorAnticipo6,
  cuotas,
  valorCuota,
  resultado,
}) => {
  return (
    <div
      className={activeItem === tipo ? style.activo : style.cardCuota}
      onClick={() => setActiveItem(tipo)}
    >
      <div
        className={
          activeItem === tipo ? style.titleCardActiva : style.titleCard
        }
      >
        {cardTitle}
      </div>
      <div className={style.subtitleCard}>
        <div
          className={
            activeItem === tipo ? style.anticipoCardActiva : style.anticipoCard
          }
        >
          {subTitleCard}
        </div>
        {tipo === "Contado" ? (
          <div
            className={
              activeItem === "Contado"
                ? style.contadoCardActiva
                : style.contadoCard
            }
          >
            Sin descuento ${resultado}
          </div>
        ) : tipo === "3 Cuotas" ? (
          <div
            className={
              activeItem === tipo
                ? style.descuentoCardActiva
                : style.descuentoCard
            }
          >
            ${valorAnticipo3}
          </div>
        ) : (
          <div
            className={
              activeItem === tipo
                ? style.descuentoCardActiva
                : style.descuentoCard
            }
          >
            ${valorAnticipo6}
          </div>
        )}
      </div>
      <div className={style.pago}>
        <div
          className={activeItem === tipo ? style.cuotasActiva : style.cuotas}
        >
          {cuotas}
        </div>
        <div
          className={
            activeItem === tipo ? style.valorCuotasActiva : style.valorCuotas
          }
        >
          ${valorCuota}
        </div>
      </div>
      <div
        className={
          activeItem === tipo
            ? style.ButtonSeleccionado
            : style.ButtonSeleccionar
        }
      >
        <div
          className={
            activeItem === tipo ? style.textSeleccionado : style.textSeleccionar
          }
        >
          {activeItem === tipo ? "Seleccionado" : "Seleccionar"}
        </div>
        <Image
          alt="flecha"
          className={
            activeItem === tipo ? style.flechaCardActiva : style.flechaCard
          }
          src={activeItem === tipo ? flechaModal : flechaModalBlue}
        />
      </div>
    </div>
  );
};

export default CustomCuota;
