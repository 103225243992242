const AnticiposDirecta = [
  {
    titleSection: "Cálculo Garantía",
    id: "calcGarantía",
    inputs: [
      [
        {
          text: "Alquiler",
          name: "alquiler",
        },
        {
          text: "Expensas",
          name: "expensas",
        },
      ],
      {
        text: "Duración",
        name: "duracion",
      },
    ],
  },
  {
    titleSection: "Porcentajes Descuento / Anticipo",
    id: "porcAnticipoDirecta",
    inputs: [
      [
        {
          text: "Descuento Contado (%)",
          name: "porcentajeDescuento",
        },
        {
          text: "Anticipo 3 Cuotas (%)",
          name: "porcentajeDescuentoTresCuotas",
        },
      ],
      {
        text: "Anticipo 6 Cuotas (%)",
        name: "porcentajeDescuentoSeisCuotas",
      },
    ],
  },
  {
    titleSection: "Datos Seguro",
    id: "incendioInputs",
    inputs: [
      {
        text: "Valor Cuotas",
        name: "valorCuotasIncendio",
      },
    ],
  },
];

export default AnticiposDirecta;
