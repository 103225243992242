import React from "react";
import style from "./styles/ReciboToPDF.module.css";
import { Firma, logoTrustPDF } from "./styles/assets";
import CustomFecha from "../CustomFecha/CustomFecha";
import CustomFooter from "../CustomFooter/CustomFooter";
import ReciboField from "./ReciboField";
import objectDocumento from "./objectDocumento";

const DocumentoRecibo = ({ inputs }) => {
  return (
    <div className={style.ContGeneral}>
      <div id="contenido" className={style.ContainerRecibo}>
        <div className={style.Header}>
          <img className={style.logoHeader} src={logoTrustPDF} />
          <div className={style.textHeader}>
            La garantía que necesitás para vivír donde querés
          </div>
        </div>

        <div className={style.BodyRecibo}>
          <div className={style.topBody}>
            <div className={style.Title}>Recibo</div>
            <CustomFecha dia={inputs.dia} mes={inputs.mes} año={inputs.año} />
          </div>

          <div className={style.datosBody}>
            {objectDocumento.map((field, index) => {
              if (Array.isArray(field)) {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                    key={index}
                  >
                    <ReciboField
                      text={field[0].text}
                      value={inputs[field[0].name]}
                    />
                    <ReciboField
                      text={field[1].text}
                      value={inputs[field[1].name]}
                      cuota={field[1].cuota}
                      totalCuotas={inputs.totalCuotas}
                    />
                  </div>
                );
              } else {
                return (
                  <ReciboField
                    text={field.text}
                    value={inputs[field.name]}
                    key={index}
                  />
                );
              }
            })}
          </div>

          <div className={style.titleObservaciones}>Observaciones</div>
          <div className={style.ContainerObservaciones}>
            {inputs.observaciones}
          </div>

          <div className={style.subBody}>
            <img className={style.Firma} src={Firma} />

            <div className={style.ContainerRecibi}>
              <div className={style.Recibi}>Recibí</div>
              <div className={style.ContainerTotal}>
                TOTAL:
                <div className={style.Total}>
                  {inputs.moneda === "Dolares" ? "U$D" : "$"} {inputs.total}
                </div>
              </div>
            </div>
          </div>
        </div>

        <CustomFooter />
      </div>
    </div>
  );
};

export default DocumentoRecibo;
