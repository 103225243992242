const IconDashboard =
  "https://res.cloudinary.com/trustfund2022/image/upload/v1662553060/CRM_TRUST%20FUND/navBarLeft/dashboard2_kfpfwh.svg";
const InmoAdheridas =
  "https://res.cloudinary.com/trustfund2022/image/upload/v1662553074/CRM_TRUST%20FUND/navBarLeft/inmoadheridas2_asph5n.svg";
const Calculadora =
  "https://res.cloudinary.com/trustfund2022/image/upload/v1662553107/CRM_TRUST%20FUND/navBarLeft/calculadora2_xcw51s.svg";
const Asesores =
  "https://res.cloudinary.com/trustfund2022/image/upload/v1662553083/CRM_TRUST%20FUND/navBarLeft/asesores2_lwsnfc.svg";

const linksArr = [
  {
    eventKey: "2",
    style: { borderBottom: "1px solid #ffffff1c", width: "auto" },
    to: "/",
    activeclassname: "active",
    styleNota: {
      width: "10.5%",
      marginRight: "11px",
      marginLeft: "-17px",
    },
    image: InmoAdheridas,
    text: "Inmobiliarias / Asesores",
    alt: "inmobiliarias",
  },
  {
    eventKey: "3",
    style: { borderBottom: "1px solid #ffffff1c", width: "auto" },
    to: "/simulador_garantia",
    activeclassname: "active",
    styleNota: {
      width: "12%",
      marginRight: "9px",
      marginLeft: "-19px",
    },
    image: Calculadora,
    text: "Simulador de garantías",
    alt: "calculador",
  },
  {
    eventKey: "4",
    style: {
      borderBottom: "1px solid #ffffff1c",
      width: "-webkit-fill-available",
    },
    to: "/recibo_pdf",
    activeclassname: "active",
    styleNota: {
      width: "12%",
      marginRight: "9px",
      marginLeft: "-19px",
    },
    image: Asesores,
    text: "Recibos",
    alt: "documento recibos",
  },
  {
    eventKey: "5",
    style: {
      borderBottom: "1px solid #ffffff1c",
      display: "flex",
      gap: "8px",
      padding: "15px 20px 15px 37px",
      width: "-webkit-fill-available",
    },
    to: "/reservas_pdf",
    activeclassname: "active",
    styleNota: null,
    image: IconDashboard,
    text: "Reservas",
    alt: "documento reservas",
  },
  {
    eventKey: "8",
    style: {
      borderBottom: "1px solid #ffffff1c",
      display: "flex",
      gap: "8px",
      padding: "15px 20px 15px 37px",
      width: "-webkit-fill-available",
    },
    to: "/contratos_pdf",
    activeclassname: "active",
    styleNota: null,
    image: IconDashboard,
    text: "Contratos",
    alt: "documento contratos",
  },
];

export default linksArr;
