const cuitMask = (e) => {
  let value = e.target.value;
  value = value.replace(/\D/g, "");

  if (value.length > 2) {
    value = value.substring(0, 2) + "-" + value.substring(2);
  }

  if (value.length > 1) {
    value = value.slice(0, -1) + "-" + value.slice(-1);
  }

  const firstHyphenIndex = value.indexOf("-");
  const lastHyphenIndex = value.lastIndexOf("-");

  if (
    firstHyphenIndex !== -1 &&
    lastHyphenIndex !== -1 &&
    firstHyphenIndex < lastHyphenIndex
  ) {
    const prefix = value.substring(0, firstHyphenIndex + 1);
    const suffix = value.substring(lastHyphenIndex);
    let middlePart = value.substring(firstHyphenIndex + 1, lastHyphenIndex);

    middlePart = middlePart
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1.")
      .split("")
      .reverse()
      .join("")
      .replace(/^[.]/, "");

    value = prefix + middlePart + suffix;
  }

  e.target.value = value;
  return e;
};

export default cuitMask;
