import React, { useEffect, useState } from "react";
import {
  ContainerSideBar,
  SubContainer,
  Header,
  SideBarMenu,
  ContainerLink,
  LinkSideBar,
  ContCotizacion,
  Nota,
} from "../styledComponents/styledSideBar";
import SlideCotizador from "../SlideCotizador/SlideCotizador";
import Solicitudes from "../../images/icons/garantias2.svg";
import linksArr from "./links";
import { useDispatch } from "react-redux";

import { getEstadisticaAdmin } from "../../actions";

import { LogoTF } from "../DocumentosPDF/ReciboToPDF/styles/assets";

export const SideBar = () => {
  const [activeKey, setActiveKey] = useState("");
  const [cotizacion, setCotizacion] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEstadisticaAdmin());
  }, []);

  const Asesores =
    "https://res.cloudinary.com/trustfund2022/image/upload/v1662553083/CRM_TRUST%20FUND/navBarLeft/asesores2_lwsnfc.svg";

  const handleNavClick = (eventkey, event) => {
    setActiveKey(eventkey);
  };

  const handleClickCotizacion = () => {
    setCotizacion(!cotizacion);
  };

  return (
    <>
      <ContainerSideBar>
        <SubContainer>
          <Header style={{ display: "flex", justifyContent: "center" }}>
            <img className="logoTrust" alt="logoTrustFund" src={LogoTF} />
          </Header>
          <SideBarMenu>
            <ContainerLink activekey={activeKey} onSelect={handleNavClick}>
              {linksArr?.map((link) => {
                return (
                  <LinkSideBar
                    eventkey={link.eventKey}
                    style={link.style}
                    to={link.to}
                    activeclassname={link.activeclassname}
                    key={link.eventKey}
                  >
                    <Nota
                      style={link.styleNota}
                      src={link.image}
                      alt={link.alt}
                      key={link.alt}
                    />
                    {link.text}
                  </LinkSideBar>
                );
              })}

              <ContCotizacion
                style={{
                  borderBottom: "1px solid #ffffff1c",
                  width: "-webkit-fill-available",
                  display: "flex",
                  padding: "15px 20px 15px 37px",
                  gap: "8px",
                  alignItems: "center",
                }}
                onClick={() => handleClickCotizacion()}
              >
                <Nota src={Solicitudes} alt={"icon-cotización"} />
                Cotización
              </ContCotizacion>
              {cotizacion ? <SlideCotizador /> : null}
            </ContainerLink>

            {/* <ContainerLink activekey={activeKey} onSelect={handleNavClick}>
              <LinkSideBar
                eventkey={9}
                style={{
                  border: "1px solid #ffffff1c",
                  display: "flex",
                  width: "-webkit-fill-available",
                  alignItems: 'center',
                }}
                to={"/profile_asesor"}
                activeclassname={"active"}
                key={9}
              >
                <Nota
                  style={{
                    width: "12%",
                    marginRight: "9px",
                    marginLeft: "-19px",
                  }}
                  src={Asesores}
                  alt={'logo_asesores'}
                  key={'logo_asesores'}
                />
                Perfil
              </LinkSideBar> */}
            {/* </ContainerLink> */}
          </SideBarMenu>
        </SubContainer>
      </ContainerSideBar>
    </>
  );
};
