import formatNumber from "./formatNumber";
import calcularAlquilerOchoPorciento from "./calcularAlquilerOchoPorciento";

const calcularValoresGarantiaDirecta = (
  alquiler,
  expensas,
  duracion,

  porcentajeDescuento,

  porcentajeDescuentoTresCuotas,

  porcentajeDescuentoSeisCuotas,

  handleChangeFormCotizacion
) => {

  const resultado = calcularAlquilerOchoPorciento(alquiler, expensas, duracion);

  const obj = {
    target: {
      name: "garantia",
      value: formatNumber(resultado),
    },
  };

  handleChangeFormCotizacion(obj);

  if (porcentajeDescuento) {
    const descuentoUnPago = resultado * porcentajeDescuento;
    const valorUnPagoDescuento = resultado - descuentoUnPago;

    const objDescuento = {
      target: {
        name: "garantiaDescuento",
        value: formatNumber(valorUnPagoDescuento),
      },
    };
    handleChangeFormCotizacion(objDescuento);

    const anticipoUnPago = valorUnPagoDescuento * 0.3; //anticipo contado al 30% en marzo
    const objAnticipo = {
      target: {
        name: "anticipo6Porciento",
        value: formatNumber(anticipoUnPago),
      },
    };
    handleChangeFormCotizacion(objAnticipo);

    const contado = valorUnPagoDescuento - anticipoUnPago;
    const objContado = {
      target: {
        name: "unPago",
        value: formatNumber(contado),
      },
    };
    handleChangeFormCotizacion(objContado);
  } else {
    const anticipoUnPago = resultado * 0.3; //anticipo contado al 30% en marzo
    const objAnticipo = {
      target: {
        name: "anticipo6Porciento",
        value: formatNumber(anticipoUnPago),
      },
    };
    handleChangeFormCotizacion(objAnticipo);

    const contado = resultado - anticipoUnPago;
    const objContado = {
      target: {
        name: "unPago",
        value: formatNumber(contado),
      },
    };
    handleChangeFormCotizacion(objContado);
  }

  if (porcentajeDescuentoTresCuotas) {
    const anticipoTresCuotas = resultado * porcentajeDescuentoTresCuotas;
    const objAnticipo = {
      target: {
        name: "anticipo3Cuotas",
        value: formatNumber(anticipoTresCuotas),
      },
    };
    handleChangeFormCotizacion(objAnticipo);

    const tresCuotas = (resultado - anticipoTresCuotas) / 2;
    const objDosCuotas = {
      target: {
        name: "dosCuotas",
        value: formatNumber(tresCuotas),
      },
    };
    handleChangeFormCotizacion(objDosCuotas);
  } else {
    const anticipoTresCuotas = resultado * 0.15;
    const objAnticipo = {
      target: {
        name: "anticipo3Cuotas",
        value: formatNumber(anticipoTresCuotas),
      },
    };
    handleChangeFormCotizacion(objAnticipo);

    const tresCuotas = (resultado - anticipoTresCuotas) / 2;
    const objDosCuotas = {
      target: {
        name: "dosCuotas",
        value: formatNumber(tresCuotas),
      },
    };
    handleChangeFormCotizacion(objDosCuotas);
  }

  if (porcentajeDescuentoSeisCuotas) {
    const interesMarzo = resultado * 0.48;
    const anticipoSeisCuotas =
      (resultado + interesMarzo) * porcentajeDescuentoSeisCuotas;
    const objAnticipo6Cuotas = {
      target: {
        name: "anticipo6Cuotas",
        value: formatNumber(anticipoSeisCuotas),
      },
    };
    handleChangeFormCotizacion(objAnticipo6Cuotas);

    const seisCuotas = (resultado + interesMarzo - anticipoSeisCuotas) / 5;
    const objSeisCuotas = {
      target: {
        name: "cincoCuotas",
        value: formatNumber(seisCuotas),
      },
    };
    handleChangeFormCotizacion(objSeisCuotas);
  } else {
    const interesMarzo = resultado * 0.48;
    const anticipoSeisCuotas = (resultado + interesMarzo) * 0.15;
    const objAnticipo6Cuotas = {
      target: {
        name: "anticipo6Cuotas",
        value: formatNumber(anticipoSeisCuotas),
      },
    };
    handleChangeFormCotizacion(objAnticipo6Cuotas);

    const seisCuotas = (resultado + interesMarzo - anticipoSeisCuotas) / 5;
    const objSeisCuotas = {
      target: {
        name: "cincoCuotas",
        value: formatNumber(seisCuotas),
      },
    };
    handleChangeFormCotizacion(objSeisCuotas);
  }
};

export default calcularValoresGarantiaDirecta;
