const inputsList = [
  {
    titleSection: "Datos Generales",
    id: "otrosInputs",
    inputs: [
      {
        text: "Asesor",
        name: "asesor",
      },
      [
        {
          text: "Día",
          name: "dia",
        },
        {
          text: "Mes",
          name: "mes",
        },
      ],
      {
        text: "Año",
        name: "año",
      },
    ],
  },
  {
    titleSection: "Datos Cliente",
    id: "clienteInputs",
    inputs: [
      [
        {
          text: "Nombre",
          name: "nombre",
        },
        {
          text: "Apellido",
          name: "apellido",
        },
      ],
      [
        {
          text: "Nacionalidad",
          name: "nacionalidad",
        },
        {
          text: "DNI",
          name: "dni",
        },
      ],
      [
        {
          text: "Teléfono",
          name: "telefono",
        },
        {
          text: "Mail",
          name: "mail",
        },
      ],
    ],
  },
  {
    titleSection: "Datos Propiedad",
    id: "propiedadInputs",
    inputs: [
      [
        {
          text: "Provincia",
          name: "provincia",
        },
        {
          text: "Localidad",
          name: "localidad",
        },
      ],
      [
        {
          text: "Código Postal",
          name: "codPostal",
        },
        {
          text: "Tipo de Propiedad",
          name: "tipoProp",
        },
      ],
      [
        {
          text: "Uso de Propiedad",
          name: "usoProp",
        },
        {
          text: "Metros Cuadrados",
          name: "metrosCuadrados",
        },
      ],
    ],
  },
];

export default inputsList;
