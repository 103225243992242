const objectRecibos = [
  {
    titleSection: "Fecha",
    id: "Fecha",
    inputs: [
      [
        {
          text: "Día",
          name: "dia",
        },
        {
          text: "Mes",
          name: "mes",
        },
      ],
      {
        text: "Año",
        name: "año",
      },
    ],
  },
  {
    titleSection: "Datos Cliente",
    id: "DatosCliente",
    inputs: [
      [
        {
          text: "Nombre y Apellido",
          name: "nombreApellido",
        },
        {
          text: "DNI",
          name: "dni",
        },
      ],
      [
        {
          text: "Contacto",
          name: "contacto",
        },
        {
          text: "Modo de Pago",
          name: "pago",
        },
      ],
      [
        {
          text: "Cuota N°",
          name: "numCuota",
        },
        {
          text: "Total de Cuotas",
          name: "totalCuotas",
        },
      ],
    ],
  },
  {
    titleSection: "Otros",
    id: "Otros",
    inputs: [
      {
        text: "Observaciones",
        name: "observaciones",
      },
    ],
  },
  {
    titleSection: "Total",
    id: "Total",
    inputs: [
      {
        name: "total",
      },
    ],
  }
];

export default objectRecibos;
