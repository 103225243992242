const cuotas = [
    {
        id: 1,
        title: '20% OFF',
        subTitle: 'Contado',
        pagos: '1 pago de ',
        cuotas: 1,
        tipo: 'Contado',
    },
    {
        id: 2,
        title: '3 cuotas s/ interés',
        subTitle: 'Anticipo del 30%',
        pagos: '2 cuotas de ',
        cuotas: 3,
        tipo: '3 Cuotas',
    },
    {
        id: 3,
        title: '6 cuotas',
        subTitle: 'Anticipo del 15%',
        pagos: '5 cuotas de ',
        cuotas: 6,
        tipo: '6 Cuotas',
    },
]

export default cuotas;