import './App.css';

import React from 'react';
import { Provider } from 'react-redux';
// import rootReducer from './reducer';
// import { applyMiddleware, createStore } from 'redux';
// import createSagaMiddleware from '@redux-saga/core';
import { store } from './store/index2';
// import { Cloudinary } from "@cloudinary/url-gen";


import ControllerRoutes from './ControllerRoutes';

function App() {

  // const sagaMiddleware = createSagaMiddleware()

  // const store = createStore(
  //   rootReducer, applyMiddleware(sagaMiddleware)
  // )

  // const cld = new Cloudinary({ cloud: { cloudName: 'trustfund2022' } });

  return (
    <div style={{ display: 'flex' }}>
      <Provider store={store}>
        <ControllerRoutes />
      </Provider>
    </div>
  );
}

export default App;
