const arrResponsables = [
  "Filtrar por Responsable",
  "Victoria",
  "Evelyn",
  "Yamila",
  "Melisa",
  "Sol",
];


export default arrResponsables;
