import React, { useEffect, useState } from "react";
import InputsRecibos from "./InputsRecibos";
import DocumentoRecibo from "./DocumentoRecibo";
import { SideBar } from "../../SideBar/SideBar";
import generarPDF from "../CotizacionesToPDF/Controllers/generarPDF";
import insertLineBreaks from "../CotizacionesToPDF/Controllers/insertLineBreaks";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReciboToPDF = ({ route }) => {
  const userState = useSelector((state) => state.asesor.state);
  const navigate = useNavigate();
  const [stateRecibo, setStateRecibo] = useState({
    dia: "",
    mes: "",
    año: "",

    nombreApellido: "",
    dni: "",
    contacto: "",
    pago: "",
    numCuota: "",
    totalCuotas: "",

    observaciones: "",

    total: "",
    moneda: "Pesos",
  });

  useEffect(() => {
    // if (!userState) {
    //   navigate("/login");
    // }
  }, []);

  const handleChangeFormRecibo = async (e) => {
    let { name, value } = e.target;

    if (name === "observaciones") {
      let newTextArea = insertLineBreaks(value, 20);

      setStateRecibo((prevStateRecibo) => ({
        ...prevStateRecibo,
        [name]: newTextArea,
      }));
    }

    setStateRecibo((prevStateRecibo) => ({
      ...prevStateRecibo,
      [name]: value,
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "-webkit-fill-available",
      }}
    >
      <SideBar />
      <div
        style={{
          display: "flex",
          width: "-webkit-fill-available",
          justifyContent: "space-between",
        }}
      >
        <InputsRecibos
          handleChangeFormRecibo={handleChangeFormRecibo}
          stateRecibo={stateRecibo}
          generarPDF={generarPDF}
          route={route}
        />
        <DocumentoRecibo inputs={stateRecibo} />
      </div>
    </div>
  );
};

export default ReciboToPDF;
