const tableFields = [
  {
    columnWidth: 250,
    header: "NOMBRE DE LA INMOBILIARIA",
    dataKey: "nombre",
    headerStyle: { color: "#ffff", backgroundColor: "#08589d" },
    cellStyle: { display: "flex", justifyContent: "center" },
    id: 'nombreInmo',
  },
  {
    columnWidth: 200,
    header: "ASESOR",
    dataKey: "asesorInmo",
    headerStyle: { color: "#ffff", backgroundColor: "#08589d" },
    cellStyle: { display: "flex", justifyContent: "center" },
    id: 'asesor',
  },
  {
    columnWidth: 150,
    header: "RESPONSABLE",
    dataKey: "asesorTF",
    headerStyle: { color: "#ffff", backgroundColor: "#08589d" },
    cellStyle: { display: "flex", justifyContent: "center" },
    id: 'responsable',
  },
  {
    columnWidth: 150,
    header: "LOCALIDAD",
    dataKey: "localidad",
    headerStyle: { color: "#ffff", backgroundColor: "#08589d" },
    cellStyle: { display: "flex", justifyContent: "center" },
    id: 'localidad',
  },
  {
    columnWidth: 455,
    header: "COMENTARIO",
    dataKey: "comentario",
    headerStyle: { color: "#ffff", backgroundColor: "#08589d" },
    cellStyle: { display: "flex", justifyContent: "center" },
    id: 'comentario',
  },
];

export default tableFields;
