import React, { useState, useEffect } from "react";
import { Modal, Button } from "rsuite";
import {
  StyledForm,
  UserDetails,
  EditDisplay,
  EditButton,
  InputBox,
  EditBox,
  Details,
  InputTag,
  InputTagEdit,
  Title,
  ModalBtn,
  LogoBox,
} from "../styledComponents/styledSolicitud";
import {
  Hero,
  Hero1,
  Nota,
} from "../styledComponents/styledInmobiliariasAdheridas";
import { editarInmo, getInmobiliarias } from "../../actions";
import InmoAdheridas from "../../images/icons/inmoadheridas.svg";
import { useDispatch } from "react-redux";
import InputsForm from "./InputsForm";
import styles from "./styles/InmobiliariasAdheridas.module.css";

const cloudName = process.env.REACT_APP_CLOUD_NAME;
const apiKey = process.env.REACT_APP_API_KEY;
const uploadPreset = process.env.REACT_APP_UPLOAD_PRESET;

const ModalInfoInmobiliaria = ({
  dataModalView,
  setModalView,
  setModalEditarInmob,
  setRender,
  render,
}) => {
  const [open, setOpen] = useState(dataModalView != null);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const [logoInmo, setLogoInmo] = useState({
    logo: "",
  });

  const dispatch = useDispatch();

  const handleFiles = async (e) => {
    if (e.target.name === "logo") {
      setLoading(true);
      const files = e.target.files;
      const data = new FormData();
      data.append("resource_type", "raw");
      data.append("file", files[0]);
      data.append("upload_preset", "ksyblkmu");
      data.append("api_key", apiKey);
      const res = await fetch(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        {
          method: "POST",
          body: data,
        }
      );

      if (res.status === 200) {
        const file = await res.json();
        setLogoInmo({ [e.target.name]: file.secure_url });
        setModalView({
          ...dataModalView,
          logo: file.secure_url,
        });
        setLoading(false);
        setEdit(true);
      } else {
        console.log("error al subir al Cloudinary", res);
        setLoading(false);
      }
    }
  };

  const handleEnviar = () => {
    dispatch(editarInmo(dataModalView)).then(dispatch(getInmobiliarias()));
    setRender(!render);
    setModalEditarInmob(false);
    setOpen(false);
    setEdit(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setModalView({
      ...dataModalView,
      [name]: value
    })
  };

  const handleClose = () => {
    setEdit(false);
    setModalEditarInmob(false);
    setOpen(null);
  };

  return (
    <>
      {open && (
        <div className="modal-container">
          <Modal size={"lg"} open={open} onClose={handleClose}>
            <Modal.Header>
              <ModalBtn>
                <Hero>
                  <Hero1 style={{ margin: "auto" }}>
                    <div className="contAdjuntar">
                      <Nota src={InmoAdheridas} alt="" />
                    </div>
                    <Title style={{ marginTop: "10px" }}>
                      Datos de la Inmobiliaria
                    </Title>
                  </Hero1>
                </Hero>
              </ModalBtn>
            </Modal.Header>
            <Modal.Body>
              <StyledForm>
                <EditDisplay>
                  <LogoBox style={{ display: 'none' }}>
                    {
                      dataModalView.logo !== null && loading === false ? (
                        <img
                          className="iconUserTableMain"
                          name="logoInmo"
                          style={{
                            borderRadius: "50%",
                            height: "100px",
                            width: "100px",
                          }}
                          src={dataModalView.logo}
                        />
                      ) : (
                        <span>LOADING</span>
                      )
                    }
                  </LogoBox>
                  <EditBox style={{ display: 'none' }}>
                    <div
                    className={styles.subEditBox}
                      type="submit"
                    >
                      <label
                        htmlFor="files"
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          width: "auto",
                        }}
                      >
                        Editar Logo
                      </label>
                      <input
                        id="files"
                        name="logo"
                        style={{ visibility: "hidden" }}
                        type="file"
                        onChange={handleFiles}
                      />
                    </div>
                  </EditBox>

                  <EditButton>
                    <div
                    className={styles.textEditDatos}
                      style={{
                        background: `${edit ? "#0b82e9" : "rgb(12, 91, 158)"}`,
                      }}
                      onClick={() => setEdit(true)}
                    >
                      Editar Datos
                    </div>
                  </EditButton>
                </EditDisplay>

                <UserDetails>
                  {InputsForm?.map((input) => {
                    if (input.option) {
                      return (
                        <InputBox style={{ margin: '20px 0 0px 0' }}>
                          <Details>{input.title}</Details>
                          {edit ? (
                            <Button className={styles.ButtonFormSelect}>
                              <select
                                name={input.inputName}
                                onChange={handleInputChange}
                                className={styles.FormSelect}
                                value={dataModalView[input.inputName]}
                              >
                                {input.option?.map((op) => {
                                  return <option value={op}>{op}</option>;
                                })}
                              </select>
                            </Button>
                          ) : (
                            <InputTag
                              disabled={true}
                              name={input.inputName}
                              value={dataModalView[input.inputName]}
                            ></InputTag>
                          )}
                        </InputBox>
                      );
                    } else {
                      return (
                        <InputBox style={{ margin: '20px 0 0px 0' }}>
                          <Details>{input.title}</Details>
                          {edit ? (
                            <InputTagEdit
                              name={input.inputName}
                              placeholder={dataModalView[input.inputName]}
                              value={dataModalView[input.inputName]}
                              onChange={handleInputChange}
                            ></InputTagEdit>
                          ) : (
                            <InputTag
                              disabled={!edit}
                              name={input.inputName}
                              value={dataModalView[input.inputName]}
                            ></InputTag>
                          )}
                        </InputBox>
                      );
                    }
                  })}
                </UserDetails>
              </StyledForm>
            </Modal.Body>
            <Modal.Footer style={{ padding: "20px 35px 0px 0px" }}>
              {edit ? (
                <EditButton>
                  <button
                    type="submit"
                    style={{
                      background: "rgb(12, 91, 158)",
                      color: "white",
                      padding: "10px 45px",
                      fontWeight: "900",
                      borderRadius: "35px",
                    }}
                    onClick={() => handleEnviar()}
                  >
                    Actualizar
                  </button>
                </EditButton>
              ) : null}
              <Button
                style={{
                  background: "#0C5B9E",
                  color: "white",
                  padding: "10px 45px 10px",
                  fontWeight: 900,
                }}
                onClick={() => handleClose()}
                color="red"
                appearance="primary"
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ModalInfoInmobiliaria;
