const ComsionesInmobiliaria = [
  {
    titleSection: "Cálculo Garantía",
    id: "calcGarantía",
    inputs: [
      [
        {
          text: "Alquiler",
          name: "alquiler",
        },
        {
          text: "Expensas",
          name: "expensas",
        },
      ],
      {
        text: "Duración",
        name: "duracion",
      },
    ],
  },
  {
    titleSection: "Porcentajes Comisiones",
    id: "porcComisionesInmobiliaria",
    inputs: [
      [
        {
          text: "Anticipo Contado (%)",
          name: "porcentajeComisionContado",
        },
        {
          text: "Anticipo 3 Meses (%)",
          name: "comisionTresMeses",
        },
      ],
      {
        text: "Anticipo 6 Meses (%)",
        name: "comisionSeisMeses",
      },
    ],
  },
  {
    titleSection: "Datos Seguro",
    id: "incendioInputs",
    inputs: [
      {
        text: "Valor Cuotas",
        name: "valorCuotasIncendio",
      },
    ],
  },
];

export default ComsionesInmobiliaria;
