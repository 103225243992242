import React from "react";
import { useState } from "react";
import {
  LogoCotizacion1,
  wppCotizacion,
  auto,
  logoFooter,
  QR,
  delSudFooter,
} from "./styles/assets/index";
import { Image } from "react-bootstrap";
import style from "./styles/CotizacionDirectaToPDF.module.css";

const CotizacionDirectaToPDF = ({formCotizacion}) => {
  const [tipoCotizacion, setTipoCotizacion] = useState("");
  const [opcional, setOpcional] = useState(false);

  return (
    <>
      <div className={style.ContainerCotizacion}>
        <div id="containerPDF" className={style.ContainerPDF}>
          <div className={style.ContainerHeaderPDF}>
            <div id="header" className={style.HeaderPDF}>
              <Image
                alt="--"
                src={LogoCotizacion1}
                className={style.LogoHeaderPDF}
              />
              <div className={style.TitlePDF}>COTIZACIÓN</div>
            </div>
          </div>

          <div className={style.RecuadroTop}>
            <div className={style.SubHeaderPDF}>
              <div className={style.subTitle}>Garantizamos tu alquiler</div>
              <div className={style.SubHeaderSide}>
                <div className={style.Asesor}>
                  Asesor: {formCotizacion.asesor}
                </div>
                <div className={style.Asesor}>
                  Fecha: {formCotizacion.dia}/{formCotizacion.mes}/
                  {formCotizacion.año}
                </div>
              </div>
            </div>
            <div id="recuadroTop" className={style.BodyPDF}>
              <div id="cliente" className={style.RecuadroCliente}>
                <div className={style.TitleRecuadro}>
                  <div className={style.HeaderPDF}>
                    Cliente
                    <div className={style.SideTitleContainer}>
                      <div className={style.ContainerCheckbox}>
                        <label className={style.labelCheckbox}>
                          Permanente
                        </label>
                        <input
                          type="checkbox"
                          id="permanente"
                          name="permanente"
                          value="permanente"
                          onClick={() => setTipoCotizacion("permanente")}
                        />
                      </div>
                      <div className={style.ContainerCheckbox}>
                        <label className={style.labelCheckbox}>
                          Temporario
                        </label>
                        <input
                          type="checkbox"
                          id="temporario"
                          name="temporario"
                          value="temporario"
                          onClick={() => setTipoCotizacion("temporario")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={style.ContenidoClientes}>
                  <div className={style.RowsContenido}>
                    <div className={style.RowDatosCliente}>
                      <div className={style.DatoCliente}>
                        Nombres: {formCotizacion.nombre}
                      </div>
                      <div className={style.DatoCliente}>
                        Apellidos: {formCotizacion.apellido}
                      </div>
                    </div>

                    <div className={style.RowDatosCliente}>
                      <div className={style.DatoCliente}>
                        Nacionalidad: {formCotizacion.nacionalidad}
                      </div>
                      <div className={style.DatoCliente}>
                        DNI/Pasaporte: {formCotizacion.dni}
                      </div>
                    </div>

                    <div className={style.RowDatosCliente}>
                      <div className={style.DatoCliente}>
                        Telefono: {formCotizacion.telefono}
                      </div>
                      <div className={style.DatoCliente}>
                        Mail: {formCotizacion.mail}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="propiedad" className={style.RecuadroPropiedad}>
                <div className={style.TitleRecuadro}>Propiedad</div>

                <div className={style.ContenidoPropiedad}>
                  <div className={style.RowDatosCliente}>
                    <div
                      className={style.labelCheckbox}
                      style={{ width: "58%" }}
                    >
                      Provincia: {formCotizacion.provincia}
                    </div>
                    <div
                      className={style.labelCheckbox}
                      style={{ width: "65%" }}
                    >
                      Localidad: {formCotizacion.localidad}
                    </div>
                    <div
                      className={style.labelCheckbox}
                      style={{ width: "20%" }}
                    >
                      CP: {formCotizacion.codPostal}
                    </div>
                  </div>

                  <div className={style.RowDatosCliente}>
                    <div
                      className={style.labelCheckbox}
                      style={{ width: "57%" }}
                    >
                      Tipo de propiedad: {formCotizacion.tipoProp}
                    </div>
                    <div
                      className={style.labelCheckbox}
                      style={{ width: "100%" }}
                    >
                      Uso: {formCotizacion.usoProp}
                    </div>
                  </div>

                  <div className={style.ContainerM2}>
                    <div className={style.DatoCliente}>
                      M² totales: {formCotizacion.metrosCuadrados}
                    </div>
                  </div>

                  <div className={style.subContainerValores}>
                    <div
                      className={style.labelCheckbox}
                      style={{ minWidth: "42.5%" }}
                    >
                      Alquiler mensual:{" "}
                      {formCotizacion.moneda === "Pesos"
                        ? `${"$"}`
                        : `${"USD "}`}
                      {formCotizacion.alquiler}
                    </div>
                    <div
                      className={style.labelCheckbox}
                      style={{ minWidth: "39%" }}
                    >
                      Monto de expensas:{" "}
                      {formCotizacion.moneda === "Pesos"
                        ? `${"$"}`
                        : `${"USD "}`}
                      {formCotizacion.expensas}
                    </div>
                    <div
                      className={style.labelCheckbox}
                      style={{ minWidth: "21%" }}
                    >
                      Duración: {formCotizacion.duracion} meses
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="recuadroAlquiler" className={style.RecuadroAlquiler}>
              <div className={style.TitleRecuadroAlquiler}>
                Garantía de Alquiler
              </div>

              <div className={style.ContainerAlquileres}>
                <div id="datosAlquiler" className={style.DatosAlquiler}>
                  <div style={{ paddingBottom: "10px" }}>
                    <div className={style.labelCheckbox}>
                      Garantía de tipo seguro de fianza.
                    </div>
                    <div className={style.BloqueAbono}>
                      <div className={style.labelCheckbox}>
                        Abonando de contado: Anticipo de{" "}
                        {formCotizacion.moneda === "Pesos"
                          ? `${"$"}`
                          : `${"USD "}`}
                        {formCotizacion.anticipo6Porciento} + 1 pago de{" "}
                        {formCotizacion.moneda === "Pesos"
                          ? `${"$"}`
                          : `${"USD "}`}
                        {formCotizacion.unPago}
                      </div>
                      {formCotizacion.porcentajeDescuento ? (
                        <div className={style.DescuentoAplicado}>
                          {" "}
                          (con %{formCotizacion.porcentajeDescuento * 100} OFF
                          aplicado)
                        </div>
                      ) : null}
                    </div>
                    {formCotizacion.modoDePago === "Cuotas" ? (
                      <div>
                        <div className={style.labelCheckbox}>Financiación:</div>
                        <div className={style.BloqueAbono}>
                          <div className={style.labelCheckbox}>
                            3 Cuotas sin interés: Anticipo de{" "}
                            {formCotizacion.moneda === "Pesos"
                              ? `${"$"}`
                              : `${"USD "}`}
                            {formCotizacion.anticipo3Cuotas} + 2 cuotas de{" "}
                            {formCotizacion.moneda === "Pesos"
                              ? `${"$"}`
                              : `${"USD "}`}
                            {formCotizacion.dosCuotas} c/u
                          </div>
                        </div>
                        <div className={style.BloqueAbono}>
                          <div className={style.labelCheckbox}>
                            6 Cuotas: Anticipo de{" "}
                            {formCotizacion.moneda === "Pesos"
                              ? `${"$"}`
                              : `${"USD "}`}
                            {formCotizacion.anticipo6Cuotas} + 5 cuotas de{" "}
                            {formCotizacion.moneda === "Pesos"
                              ? `${"$"}`
                              : `${"USD "}`}
                            {formCotizacion.cincoCuotas} c/u
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className={style.text}>
                    Los valores expresados no incluyen IVA. En caso de abonar de
                    contado, el restante del anticipo se abona al momento de la
                    firma del contrato de garantía. En caso de requerír factura,
                    la facturación se realizará mes a mes con el abono de las
                    cuotas.
                  </div>
                </div>
                <div id="costoGarantia" className={style.CostoGarantia}>
                  <div className={style.TextCosto}>Costo garantía:</div>
                  {formCotizacion.garantiaDescuento ? (
                    <div className={style.ContainerCosto}>
                      <div className={style.ContainerCostoAnterior}>
                        <div className={style.Antes}>Antes</div>
                        <div className={style.Garantia}>
                          <s>
                            {formCotizacion.moneda === "Pesos"
                              ? `${"$"}`
                              : `${"USD "}`}{" "}
                            {formCotizacion.garantia}
                          </s>
                        </div>
                      </div>
                      <div className={style.ContainerCostoActual}>
                        <div className={style.TextCostoActual}>Ahora</div>
                        <div className={style.ValorGarantia}>
                          {formCotizacion.moneda === "Pesos"
                            ? `${"$"}`
                            : `${"USD "}`}{" "}
                          {formCotizacion.garantiaDescuento}
                        </div>
                        <div className={style.TextCostoActual}>
                          Con %{formCotizacion.porcentajeDescuento * 100} OFF
                          aplicado
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={style.ValorGarantia}>
                      {formCotizacion.moneda === "Pesos"
                        ? `${"$"}`
                        : `${"USD "}`}{" "}
                      {formCotizacion.garantia}
                    </div>
                  )}
                  <div className={style.ValorSinIva}>
                    Valor sin IVA pagando de contado.
                  </div>
                </div>
              </div>
            </div>

            <div id="recuadroIncendios" className={style.RecuadroAlquiler}>
              <div className={style.ContainerSeguro}>
                <div className={style.textSeguro}>
                  Seguro de Incendios - Recomendado
                </div>
                <div className={style.BloqueAbono}>
                  <label style={{ fontSize: "24px" }}>Opcional</label>
                  <input
                    type="checkbox"
                    id="opcional"
                    name="opcional"
                    value="opcional"
                    onClick={() => setOpcional(true)}
                  />
                </div>
              </div>
              <div className={style.HeaderPDF}>
                <div id="datosIncendio" className={style.DatosIncendio}>
                  <div className={style.ContainerDatosIncendio}>
                    {formCotizacion.contratacion === "Comercio" ? (
                      <div className={style.textIncendio}>
                        <div className={style.labelCheckbox}>
                          Tipo Póliza: INTEGRAL DE COMERCIO / VIGENCIA
                          TRIMESTRAL
                        </div>
                        <div className={style.labelCheckbox}>
                          Riesgo: COMERCIO
                        </div>
                      </div>
                    ) : (
                      <div className={style.textIncendio}>
                        <div className={style.labelCheckbox}>
                          Tipo Póliza: INCENDIO DE VIVIENDA CLÁSICO / VIGENCIA
                          ANUAL
                        </div>
                        <div className={style.labelCheckbox}>
                          Riesgo: CASA Y/O DEPTO EN PLANTA BAJA O PISOS ALTOS
                        </div>
                      </div>
                    )}
                  </div>
                  {formCotizacion.contratacion === "Comercio" ? (
                    <div>
                      <div className={style.text}>
                        100 Incendio edificio $1.000.000. 101 Huracán, vendaval,
                        ciclón o tornado $1.000.000.
                      </div>
                      <div className={style.text}>
                        110 Remoción de escombros edificio $50.000. 112 Gastos
                        extras $50.000.
                      </div>
                      <div className={style.text}>
                        200 Incendio contenido gral. $50.000. 100
                        Responsabilidad civil comprensiva $250.000
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={style.text}>
                        101 Incendio edificio $15.000.000. 202 Huracán,
                        vendaval, ciclón o tornado $15.000.000.
                      </div>
                      <div className={style.text}>
                        235 Remoción de escombros edificio $750.000. 236 Gastos
                        extras $750.000.
                      </div>
                    </div>
                  )}
                </div>
                <div
                  id="costoSeguroIncendio"
                  className={style.costoSeguroIncendio}
                >
                  <div className={style.TextCosto}>
                    {formCotizacion.contratacion === "Comercio"
                      ? " 3 cuotas de"
                      : " 12 cuotas de"}
                  </div>
                  <div className={style.valorCuotaIncendio}>
                    $ {formCotizacion.valorCuotasIncendio}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="contratación" className={style.ContainerContratacion}>
            Finalizá la contratación
            <div>
              <Image src={wppCotizacion} className={style.logoWpp} alt="wpp" />
              221 3619465
            </div>
          </div>

          <div className={style.ContainerSeguroAuto}>
            <div className={style.BarraAzul}>
              <Image src={auto} className={style.Auto} />
              <div className={style.ContainerTextSeguro}>
                <div className={style.RowTextSeguro}>
                  <div className={style.TextSeguroAuto}>
                    Asegurá tu auto o moto con{" "}
                  </div>
                  <div className={style.TextReintegro}>20% de reintegro</div>
                </div>
                <div className={style.Cotizalo}>¡Cotizalo gratis!</div>
              </div>
            </div>
          </div>

          <div id="footer" className={style.ContainerFooter}>
            <div className={style.SubContainerFooter}>
              <div className={style.RowTopFooter}>
                <div className={style.LeftFooter}>
                  <Image src={logoFooter} className={style.logoFooter} />
                  <div className={style.DatosFooter}>
                    <div>
                      Calle 37 N°125 e/ 117 y 118 / La Plata, Buenos Aires,
                      Argentina.
                    </div>
                    <div>info@trustfund.com.ar / www.trustfund.com.ar</div>
                  </div>
                </div>
                <Image src={delSudFooter} className={style.logoDelSur} />
              </div>
              <div className={style.textDisclaimer}>
                Cotización válida por 15 días a partír de la fecha de la emisión
                de este documento. Las variaciones en el monto de alquiler
                mensual inicial, expensas o duración del contrato de locación
                previstas, entre la fecha de pago del anticipo y la firma de la
                garantía, se traducirán en la modificación del costo de la
                garantía. En los casos en que la diferencia resultase en un
                incremento del costo, el solicitante abonará la diferencia. Si
                el valor fuese menor al previsto, Trust Fund no realizará
                reembolso alguno. • Proveedor de seguros MERCI PRODUCTORES
                ASESORES DE SEGUROS S.A Matrícula N°1722 • El valor de la
                cotización de los seguros son estimativos y pueden variar a la
                hora de efectivizar la contratación de los mismos • Grupo Delsud
                Administración financiera e inmobiliaria S.R.L
              </div>
            </div>
            <div className={style.ContainerQR}>
              <Image src={QR} style={{ scale: "200%", alignSelf: "center" }} />
              <div style={{ fontSize: "10px" }}>
                Atención al cliente casa central
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CotizacionDirectaToPDF;
