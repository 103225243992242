import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Hero,
  Hero2,
  Search,
  IcoSearch,
  Section,
} from "../styledComponents/styledInmobiliariasAdheridas";
import { getInmobiliariasByResponsable } from "../../actions";
import { CSVLink, CSVDownload } from "react-csv";
import { ButtonToolbar, Button } from "rsuite";
import EmailFillIcon from "@rsuite/icons/EmailFill";
import arrResponsables from "./responsables";
import styles from "./styles/InmobiliariasAdheridas.module.css";

const Toolbar = ({
  setBusqueda,
  busqueda,
  setFilter,
  filter,
  setResponsables,
  responsables,
  inmobiliarias,
  setModalAgregarInmob,
  headers,
  inmosFiltradas,
  currentInmos,
}) => {
  const dispatch = useDispatch();

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const element = document.getElementById("responsablesDisplay");

    element.addEventListener("mouseover", () => {
      element.classList.add("hovered");
      setClicked(true);
    });

    element.addEventListener("mouseout", () => {
      setClicked(false);
      element.classList.remove("hovered");
    });
  }, []);

  const handleFilter = (filter) => {
    setFilter(filter);
  };

  const toggleResponsables = (e) => {
    setResponsables(e);
    dispatch(getInmobiliariasByResponsable(e));
  };

  function modalAgregar() {
    setModalAgregarInmob(true);
  }

  return (
    <Hero style={{ width: "-webkit-fill-available" }}>
      <Hero2>
        <Search
          type="text"
          onChange={(e) => setBusqueda(e.target.value)}
          value={busqueda.data}
          name="dataBase"
          key='buscador'
          placeholder={
            filter === "nombre"
              ? `Nombre de la inmobiliaria...`
              : filter === "localidad"
              ? `Nombre del localidad...`
              : filter === "asesor"
              ? `Nombre del asesor...`
              : null
          }
        />
        <IcoSearch />
        <ButtonToolbar>
          <Button
            className={styles.ButtonsFilter}
            onClick={() => handleFilter("nombre")}
            style={{
              background: `${filter === "nombre" ? "#0b82e9" : "#08589d"}`,
            }}
            key='filtroNombre'
          >
            Filtrar por Nombre
          </Button>
          <Button
            className={styles.ButtonsFilter}
            onClick={() => handleFilter("localidad")}
            style={{
              background: `${filter === "localidad" ? "#0b82e9" : "#08589d"}`,
            }}
            key='filtroLocalidad'
          >
            Filtrar por Localidad
          </Button>
          <Button
            className={styles.ButtonsFilter}
            onClick={() => handleFilter("asesor")}
            style={{
              background: `${filter === "asesor" ? "#0b82e9" : "#08589d"}`,
            }}
            key='filtroAsesor'
          >
            Filtrar por Asesor
          </Button>
          <select
            id="responsablesDisplay"
            style={{
              background: `${clicked ? "#0b82e9" : "#08589d"}`,
              border: "none",
              outline: "none",
              cursor: "pointer",
              borderRadius: '6px',
              padding: '6px 20px 6px 20px'
            }}
            className={styles.ButtonsFilter}
            onChange={(e) => toggleResponsables(e.target.value)}
          >
            {arrResponsables.map((resp) => {
              return <option key={resp} value={resp}>{resp}</option>;
            })}
          </select>
        </ButtonToolbar>
      </Hero2>

      <Section>
        <ButtonToolbar>
          <Button onClick={() => modalAgregar()} className={styles.ButtonRight} key='agregar'>
            {" "}
            + Agregar Inmobiliaria{" "}
          </Button>
        </ButtonToolbar>
        <ButtonToolbar>
          <CSVLink
            className={styles.ButtonRight}
            style={{
              padding: "11px 20px",
              borderRadius: "5px",
              gap: "10px",
              display: "flex",
              alignItems: "center",
            }}
            data={
              busqueda
                ? inmosFiltradas?.length > 0
                  ? inmosFiltradas
                  : ""
                : currentInmos !== null
                ? currentInmos
                : ""
            }
            headers={headers}
            filename={"INMOBILIARIAS.CSV"}
            separador={" "}
          >
            <EmailFillIcon></EmailFillIcon>
            Descargar Página
          </CSVLink>
        </ButtonToolbar>
      </Section>
    </Hero>
  );
};

export default Toolbar;
