const object1Cogarante = [
  [
    {
      titleSection: "Datos Co-Garantes",
      id: "datosCogarantes",
      inputs: [
        [
          {
            text: "Co-Garante 1",
            name: "nombreCogarante1",
          },
          {
            text: "DNI Co-Garante 1",
            name: "dniCogarante1",
          },
        ],
        [
          {
            text: "Domicilio Co-Garante 1",
            name: "domicilioCogarante1",
          },
          {
            text: "Correo Co-Garante 1",
            name: "mailCogarante1",
          },
        ],
      ],
    },
  ],
];

export default object1Cogarante;
