import formatNumber from "./formatNumber";
import calcularAlquilerOchoPorciento from "./calcularAlquilerOchoPorciento";

const calcularValoresGarantiaInmobiliaria = (
  alquiler,
  expensas,
  duracion,

  porcentajeComisionContado,

  comisionTresMeses,

  comisionSeisMeses,

  handleChangeFormCotizacion
) => {
  const resultado = calcularAlquilerOchoPorciento(alquiler, expensas, duracion);

  const obj = {
    target: {
      name: "garantia",
      value: formatNumber(resultado),
    },
  };

  handleChangeFormCotizacion(obj);

  if (porcentajeComisionContado) {
    const comision = resultado * porcentajeComisionContado;
    const comisionContado = resultado - comision;

    const objUnPago = {
      target: {
        name: "unPago",
        value: formatNumber(comisionContado),
      },
    };

    handleChangeFormCotizacion(objUnPago);

    const objAnticipo6Porciento = {
      target: {
        name: "anticipo6Porciento",
        value: formatNumber(comision),
      },
    };

    handleChangeFormCotizacion(objAnticipo6Porciento);
  } else {
    const comision = resultado * 0.15;
    const comisionContado = resultado - comision;

    const objUnPago = {
      target: {
        name: "unPago",
        value: formatNumber(comisionContado),
      },
    };
    handleChangeFormCotizacion(objUnPago);

    const anticipoUnPago = comisionContado * 0.3;

    const objAnticipo6Porciento = {
      target: {
        name: "anticipo6Porciento",
        value: formatNumber(anticipoUnPago),
      },
    };
    handleChangeFormCotizacion(objAnticipo6Porciento);
  }

  if (comisionTresMeses) {
    const comision = resultado * comisionTresMeses;

    const objComision3Cuotas = {
      target: {
        name: "anticipo3Cuotas",
        value: formatNumber(comision),
      },
    };
    handleChangeFormCotizacion(objComision3Cuotas);

    const comisionTresCuotas = resultado - comision;
    const tresCuotas = comisionTresCuotas / 2;
    const objDosCuotas = {
      target: {
        name: "dosCuotas",
        value: formatNumber(tresCuotas),
      },
    };
    handleChangeFormCotizacion(objDosCuotas);
  } else {
    const comisionTresCuotas = resultado * 0.15;
    const objComision3Cuotas = {
      target: {
        name: "anticipo3Cuotas",
        value: formatNumber(comisionTresCuotas),
      },
    };
    handleChangeFormCotizacion(objComision3Cuotas);

    const tresCuotas = (resultado - comisionTresCuotas) / 2;
    const objDosCuotas = {
      target: {
        name: "dosCuotas",
        value: formatNumber(tresCuotas),
      },
    };
    handleChangeFormCotizacion(objDosCuotas);
  }

  if (comisionSeisMeses) {
    const interesMarzo = resultado * 0.48;
    const anticipoSeisCuotas = (resultado + interesMarzo) * comisionSeisMeses;

    const objComision6Cuotas = {
      target: {
        name: "anticipo6Cuotas",
        value: formatNumber(anticipoSeisCuotas),
      },
    };

    handleChangeFormCotizacion(objComision6Cuotas);

    const comisionSeisCuotas = resultado + interesMarzo - anticipoSeisCuotas;
    const seisCuotas = comisionSeisCuotas / 5;
    const objSeisCuotas = {
      target: {
        name: "cincoCuotas",
        value: formatNumber(seisCuotas),
      },
    };

    handleChangeFormCotizacion(objSeisCuotas);
  } else {
    const interesMarzo = resultado * 0.48;
    const anticipoSeisCuotas = (resultado + interesMarzo) * 0.15;

    const comisionSeisCuotas = resultado + interesMarzo - anticipoSeisCuotas;

    const objComision6Cuotas = {
      target: {
        name: "anticipo6Cuotas",
        value: formatNumber(comisionSeisCuotas),
      },
    };
    handleChangeFormCotizacion(objComision6Cuotas);

    const seisCuotas = comisionSeisCuotas / 5;

    const objSeisCuotas = {
      target: {
        name: "cincoCuotas",
        value: formatNumber(seisCuotas),
      },
    };
    handleChangeFormCotizacion(objSeisCuotas);
  }
};

export default calcularValoresGarantiaInmobiliaria;
