import React from "react";
import style from "./styles/LoginInput.module.css";

const LoginInput = ({ title, name, type, handleChangeLogin }) => {
  return (
    <div className={style.inputLoginContainer}>
      <div>{title}</div>
      <input
        className={style.inputLogin}
        name={name}
        type={type}
        onChange={(e) => handleChangeLogin(e)}
      ></input>
    </div>
  );
};

export default LoginInput;
