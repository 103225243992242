import React, { useEffect, useState } from "react";
import LoginInput from "./LoginInput/LoginInput";
import objectLogin from "./LoginInput/objectLogin";
import LoginButton from "./LoginButton/LoginButton";
import style from "./styles/Login.module.css";

const Login = () => {
  const [formLogin, setFormLogin] = useState({
    email: "",
    pass: "",
  });

  const handleChangeLogin = (e) => {
    let { name, value } = e.target;

    setFormLogin((prevFormLogin) => ({
      ...prevFormLogin,
      [name]: value,
    }));
  };

  return (
    <div className={style.LoginInputsContainer}>
      {objectLogin.map((log, index) => {
        return (
          <LoginInput
            title={log.title}
            name={log.name}
            type={log.type}
            handleChangeLogin={handleChangeLogin}
            key={index}
          />
        );
      })}
      <LoginButton formLogin={formLogin} />
    </div>
  );
};

export default Login;
