import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SideBar } from "../../SideBar/SideBar";
import InputsCotizaciones from "./InputsCotizaciones";
import CotizacionDirectaToPDF from "./CotizacionDirectaToPDF";
import CotizacionInmobiliariaToPDF from "./CotizacionInmobiliariaToPDF";

import generarPDF from "./Controllers/generarPDF";
import currencyMask from "./Controllers/currencyMask";
import calcularValoresGarantiaDirecta from "./Controllers/calcularValoresGarantiaDirecta";
import calcularValoresGarantiaInmobiliaria from "./Controllers/calcularValoresGarantiaInmobiliaria";
import { useNavigate } from "react-router-dom";

const CotizacionesToPDF = ({ route }) => {
  const userState = useSelector((state) => state.asesor.state);
  const navigate = useNavigate();

  const [formCotizacion, setFormCotizacion] = useState({
    contratacion: "Vivienda",

    asesor: "",
    dia: "",
    mes: "",
    año: "",

    nombre: "",
    apellido: "",
    nacionalidad: "",
    dni: "",
    telefono: "",
    mail: "",

    provincia: "",
    localidad: "",
    codPostal: "",
    tipoProp: "",
    usoProp: "",
    metrosCuadrados: "",

    alquiler: "",
    expensas: "",
    duracion: "",

    anticipo6Porciento: "",
    anticipo3Cuotas: "",
    anticipo6Cuotas: "",
    unPago: "",
    dosCuotas: "",
    cincoCuotas: "",
    garantia: "",
    garantiaDescuento: "",

    moneda: "Pesos",

    modoDePago: "Contado",

    porcentajeDescuento: "",
    porcentajeDescuentoTresCuotas: "",
    porcentajeDescuentoSeisCuotas: "",

    porcentajeComisionContado: "",
    comisionTresMeses: "",
    comisionSeisMeses: "",

    valorCuotasIncendio: "",
  });

  useEffect(() => {
    // if (!userState) {
    //   navigate("/login");
    // }
  }, []);

  const handleChangeFormCotizacion = async (e) => {
    let { name, value } = e.target;

    if (
      name === "porcentajeDescuento" ||
      name === "porcentajeDescuentoTresCuotas" ||
      name === "porcentajeDescuentoSeisCuotas" ||
      name === "porcentajeComisionContado" ||
      name === "comisionTresMeses" ||
      name === "comisionSeisMeses"
    ) {
      let descuento = value * 0.01;

      setFormCotizacion((prevFormCotizacion) => ({
        ...prevFormCotizacion,
        [name]: descuento,
      }));
    } else {
      setFormCotizacion((prevFormCotizacion) => ({
        ...prevFormCotizacion,
        [name]: value,
      }));
    }
  };

  const handleCalcularGarantia = () => {
    if (
      formCotizacion.alquiler &&
      formCotizacion.expensas &&
      formCotizacion.duracion
    ) {
      if (route === "/cotizacion_directa_pdf") {
        calcularValoresGarantiaDirecta(
          formCotizacion.alquiler,
          formCotizacion.expensas,
          formCotizacion.duracion,

          formCotizacion.porcentajeDescuento,

          formCotizacion.porcentajeDescuentoTresCuotas,

          formCotizacion.porcentajeDescuentoSeisCuotas,

          handleChangeFormCotizacion
        );
      } else if (route === "/cotizacion_inmobiliaria_pdf") {
        calcularValoresGarantiaInmobiliaria(
          formCotizacion.alquiler,
          formCotizacion.expensas,
          formCotizacion.duracion,

          formCotizacion.porcentajeComisionContado,

          formCotizacion.comisionTresMeses,

          formCotizacion.comisionSeisMeses,

          handleChangeFormCotizacion
        );
      }
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <InputsCotizaciones
          generarPDF={generarPDF}
          handleCalcularGarantia={handleCalcularGarantia}
          currencyMask={currencyMask}
          formCotizacion={formCotizacion}
          handleChangeFormCotizacion={handleChangeFormCotizacion}
          route={route}
        />
        {route === "/cotizacion_directa_pdf" && (
          <CotizacionDirectaToPDF formCotizacion={formCotizacion} />
        )}
        {route === "/cotizacion_inmobiliaria_pdf" && (
          <CotizacionInmobiliariaToPDF formCotizacion={formCotizacion} />
        )}
      </div>
    </div>
  );
};

export default CotizacionesToPDF;
