import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const generarPDFContrato = async function (nombre, id, setloading) {
  // Set the maximum height for each page in pixels
  const pageHeight = 800;

  const margin = 15;

  // Get the HTML content container element
  const contentContainer = document.getElementById(id);

  // Calculate the total height of the HTML content
  const totalHeight = contentContainer?.offsetHeight;

  // Calculate the number of pages needed
  const totalPages = Math.ceil(totalHeight / pageHeight) - 4;

  // Create a new instance of jsPDF for each page
  const doc = new jsPDF("p", "pt", "letter");

  // Loop through each page
  let yPos = 0;
  for (let page = 0; page < totalPages; page++) {
    // Create a new canvas element for the current page
    const canvas = document.createElement("canvas");
    canvas.width = doc.internal.pageSize.getWidth();

    canvas.height = pageHeight;

    // Set the correct position on the canvas
    const context = canvas.getContext("2d");
    context.translate(0, -yPos);

    // Calculate the scale factor to fit the content within the canvas
    const scaleFactor = canvas.width / document.getElementById(id).offsetWidth;
    console.log('scaleFactor: ', scaleFactor)

    // Apply the scale factor to the canvas context
    context.scale(scaleFactor, scaleFactor);

    await html2canvas(contentContainer, { canvas });

    // Convert the canvas to an image and add it to the PDF document
    const imgData = canvas.toDataURL("image/png");
    doc.addImage(
      imgData,
      "PNG",
      0,
      margin,
      canvas.width,
      pageHeight - margin * 5
    );

    // Increment the y-position for the next page
    yPos += pageHeight;

    // Add a new page if it's not the last page
    if (page < totalPages - 1) {
      doc.addPage();
    }
  }

  setloading(false);
  // Save the PDF or do something else with it
  doc.save(`Contrato ${nombre}.pdf`);
};

export default generarPDFContrato;
