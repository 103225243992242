import styled from "styled-components";
import {AiOutlineArrowLeft} from 'react-icons/ai';
import {FiSearch} from 'react-icons/fi'
import { Link, NavLink } from "react-router-dom";

export const Container = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
   background: #FFF;
   color: #335998;
   width: -webkit-fill-available;
`
export const ContainerLoader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
`
export const Wrapper = styled.div`
    width: 88%;
    background: #fff;
    border-radius: 25px;
`
export const Hero = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 14px 0px;
`
export const Section = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`
export const Hero1 = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`
export const Hero2 = styled.div`
    position: relative;
	width: auto;
    display: flex;
    align-items: center;
    gap: 20px;
`
export const Search = styled.input`
    padding: 8px 15px 10px 35px;
    width: 250px;
    border: 1px solid #01579A;
    border-radius: 30px;
`
export const ButtonSearch = styled.button`
    border: 1px solid #00B4CC;
    background: #00B4CC;
    text-align: center;
    color: #01579A;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
    font-size: 20px;
`
export const ModalBtn = styled.div`
    text-align: center;
`

export const IcoSearch = styled(FiSearch)`
    color: #01579A;
    width: 20px;
    height: 20px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
`

export const Route = styled(Link)`
    width: 45px;
`
export const ArrowLeft = styled(AiOutlineArrowLeft)`
    display: block;
    fill: #57C1E3;
    font-size: 30px;
`
export const Nota = styled.img`
    width: auto;
`
export const LinkNavBar = styled(NavLink)`
    background-color: #08589D;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.25;
    padding: 15px 20px 15px 56px;
    white-space: normal;
    width: 90%;
    font-weight: 600;
    &:hover {
        text-decoration: none !important;
        color: #6c757d;
    }
    &.active {
    color: white;
    background-color: #ffffff38;
    border-radius: 5px;
  }

`
export const Title = styled.h2`
    color: rgb(1, 87, 154) !important;
`
export const ContainerLink = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`