import React from "react";
import {
    TrustFooter,
    LogoDelSud,
} from './styles/assets';
import style from './styles/CustomFooter.module.css';

const CustomFooter = () => {

    return (
        <div className={style.Footer}>
            <div className={style.rowTop}>
                <img src={TrustFooter} />
                forma parte de
                <img style={{ scale: '85%' }} src={LogoDelSud} />
            </div>

            <div className={style.rowMid}>
                Calle 37 N° 125 e/ 117 y 118 C.P 1900 | La Plata | info@trustfund.com.ar
            </div>

            <div className={style.rowBot}>
                www.trustfund.com.ar
            </div>
        </div>
    )
}

export default CustomFooter;