import React from "react";
import { ModalBtn } from "../styledComponents/styledInmobiliariasAdheridas";
import { Button } from "rsuite";
import { useDispatch } from "react-redux";
import { editVentasInmo } from "../../actions";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import TrashIcon from "@rsuite/icons/Trash";
import PageIcon from "@rsuite/icons/Page";
import tableFields from "./TableFields";

const TablaInmobiliarias = ({
  busqueda,
  inmosFiltradas,
  currentInmos,
  changeVenta,
  setChangeVenta,
  editedVentasLocal,
  setEditedVentasLocal,
  setModalEditarInmob,
  setModalView,
  setIdEliminar,
  setModalEliminarInmob,
}) => {
  const dispatch = useDispatch();

  function changeVentas(id) {
    setChangeVenta(true);

    if (editedVentasLocal !== null) {
      console.log(editedVentasLocal, id);
      dispatch(editVentasInmo(editedVentasLocal, id));
      setChangeVenta(false);
      setEditedVentasLocal(null);
    }
  }

  const handleChangeVenta = (value) => {
    console.log(value);
    setEditedVentasLocal(value);
  };

  function handleView(rowData) {
    setModalEditarInmob(true);
    setModalView(rowData);
  }

  function modalEliminar(id) {
    setIdEliminar(id);
    setModalEliminarInmob(true);
  }

  return (
    <Table
      data={
        busqueda
          ? inmosFiltradas?.length > 0
            ? currentInmos
            : ""
          : currentInmos !== null
          ? currentInmos
          : ""
      }
      locale={{ emptyMessage: "SIN INMOBILIARIAS" }}
      height={500}
      style={{ borderRadius: "15px" }}
      hover={true}
      bordered={true}
      cellBordered={true}
    >
      {tableFields?.map((field) => {
        return (
          <Column key={field.id} width={field.columnWidth}>
            <HeaderCell style={field.headerStyle}>{field.header}</HeaderCell>
            <Cell dataKey={field.dataKey} style={field.cellStyle} />
          </Column>
        );
      })}

      <Column width={100} key="fechaAdherida">
        <HeaderCell style={{ color: "#ffff", backgroundColor: "#08589d" }}>
          FECHA ADHERIDA
        </HeaderCell>
        <Cell
          dataKey="createdAt"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {(rowData) => {
            return <div key={rowData.dataKey}>{rowData.createdAt.substr(0, 10)}</div>;
          }}
        </Cell>
      </Column>

      <Column className="tabStyle" width={60} key="editar">
        <HeaderCell
          className="headTable"
          style={{ color: "#ffff", backgroundColor: "#08589d" }}
        >
          EDITAR
        </HeaderCell>
        <Cell style={{ display: "flex", justifyContent: "center" }}>
          {(rowData) => {
            return (
              <ModalBtn key={rowData.dataKey}>
                <PageIcon
                  onClick={() => handleView(rowData)}
                  style={{
                    fontSize: "1.8em",
                    cursor: "pointer",
                    paddingRight: "5px",
                    marginTop: "-7px",
                  }}
                />
              </ModalBtn>
            );
          }}
        </Cell>
      </Column>

      <Column width={80} key="eliminar">
        <HeaderCell style={{ color: "#ffff", backgroundColor: "#08589d" }}>
          ELIMINAR
        </HeaderCell>
        <Cell
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "7px 10px",
          }}
        >
          {(rowData) => {
            return (
              <div>
                <Button
                  key={rowData.dataKey}
                  onClick={() => modalEliminar({ id: rowData.id })}
                  className="butFilter-borrador"
                  style={{
                    background: "#08589D",
                    color: "white",
                  }}
                >
                  {" "}
                  <TrashIcon></TrashIcon>
                </Button>
              </div>
            );
          }}
        </Cell>
      </Column>
    </Table>
  );
};

export default TablaInmobiliarias;
