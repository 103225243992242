const objectInputs = [
  {
    titleSection: "Fecha Actual",
    id: "FechaActual",
    inputs: [
      [
        {
          text: "Día Actual",
          name: "dia",
        },
        {
          text: "Mes Actual",
          name: "mes",
        },
      ],
      {
        text: "Año Actual",
        name: "año",
      },
    ],
  },
  {
    titleSection: "Datos Solicitante",
    id: "DatosSolicitante",
    inputs: [
      [
        {
          text: "Nombre",
          name: "nombre",
        },
        {
          text: "DNI",
          name: "dni",
        },
      ],
      {
        text: "Domicilio",
        name: "domicilio",
      },
    ],
  },
  {
    titleSection: "Montos Garantia",
    id: "MontosGarantia",
    inputs: [
      [
        {
          text: "Reserva",
          name: "reserva",
        },
        {
          text: "Garantía",
          name: "garantia",
        },
      ],
      [
        {
          text: "Alquiler",
          name: "alquiler",
        },
        {
          text: "Expensas",
          name: "expensas",
        },
      ],
      [
        {
          text: "Cantidad de Cuotas",
          name: "cuotas",
        },
        {
          text: "Monto Cuotas",
          name: "monto",
        },
      ],
    ],
  },
  {
    titleSection: "Fecha Vencimiento",
    id: "FechaVencimiento",
    inputs: [
      [
        {
          text: "Día Vencimiento",
          name: "diaVig",
        },
        {
          text: "Mes Vencimiento",
          name: "mesVig",
        },
      ],
      {
        text: "Año Vencimiento",
        name: "añoVig",
      },
    ],
  },
];

export default objectInputs;
