function insertLineBreaks(input, maxLength) {
  let result = "";
  let i = 0;

  while (i < input.length) {
    // Check if we're at maxLength and the next character is a space
    if (i + maxLength < input.length && input.charAt(i + maxLength) === " ") {
      result += input.substring(i, i + maxLength + 1) + "\n";
      i += maxLength + 1; // Skip the space
    } else {
      // Find the last space within maxLength characters
      let breakIndex = input.lastIndexOf(" ", i + maxLength);
      if (breakIndex !== -1 && breakIndex >= i) {
        result += input.substring(i, breakIndex + 1) + "\n";
        i = breakIndex + 1;
      } else {
        result += input.substring(i);
        break; // No more breaks needed
      }
    }
  }

  return result.trim(); // Trim to remove extra newline at the end
}

export default insertLineBreaks;