import React from "react";
import { LinkSideBar, SubContCotizador } from "../styledComponents/styledSideBar";

const SlideCotizador = () => {
  return (
    <SubContCotizador>
      <LinkSideBar
        eventkey="6"
        style={{ borderBottom: "1px solid #ffffff1c" }}
        to="/cotizacion_directa_pdf"
        activeclassname="active"
      >
        Directa
      </LinkSideBar>
      <LinkSideBar
        eventkey="7"
        style={{ borderBottom: "1px solid #ffffff1c" }}
        to="/cotizacion_inmobiliaria_pdf"
        activeclassname="active"
      >
        Inmobiliaria
      </LinkSideBar>
    </SubContCotizador>
  );
};

export default SlideCotizador;
