import React from "react";
import inputsList from "./InputsList";
import AnticiposDirecta from "./InputSection/AnticiposDirecta";
import ComsionesInmobiliaria from "./InputSection/ComisionesInmobiliaria";
import ButtonsInputs from "./buttonsInputs/ButtonsInputs";
import InputSection from "./InputSection/InputSection";
import buttons from "./buttonsInputs/buttons";
import style from "./styles/CotizacionDirectaToPDF.module.css";

const InputsCotizaciones = ({
  generarPDF,
  handleCalcularGarantia,
  currencyMask,

  formCotizacion,
  handleChangeFormCotizacion,

  route,
}) => {
  const { buttonsTipoProp, buttonsPago, buttonsMoneda } = buttons;

  const handleClickContratacion = (value) => {
    const obj = {
      target: {
        name: "contratacion",
        value: value,
      },
    };
    handleChangeFormCotizacion(obj);
  };

  const handleClickPago = (value) => {
    const obj = {
      target: {
        name: "modoDePago",
        value: value,
      },
    };
    handleChangeFormCotizacion(obj);
  };

  const handleClickMoneda = (value) => {
    const obj = {
      target: {
        name: "moneda",
        value: value,
      },
    };
    handleChangeFormCotizacion(obj);
  };

  return (
    <div>
      <div id="inputsContainer" style={{ width: "-webkit-fill-available" }}>
        <div className={style.TopHeader}>
          <div className={style.TitleHeader}>
            {route === "/cotizacion_directa_pdf" && "COTIZACIÓN DIRECTA"}
            {route === "/cotizacion_inmobiliaria_pdf" &&
              "COTIZACIÓN INMOBILIARIA"}
          </div>

          <div className={style.ContainerButtons}>
            {buttonsTipoProp.map((button, index) => {
              return (
                <ButtonsInputs
                  text={button}
                  value={formCotizacion.contratacion}
                  handleClick={handleClickContratacion}
                  key={index}
                />
              );
            })}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {inputsList.map((input, index) => {
            return (
              <InputSection
                id={input.id}
                formCotizacion={formCotizacion}
                buttonsPago={buttonsPago}
                buttonsMoneda={buttonsMoneda}
                handleClickPago={handleClickPago}
                handleClickMoneda={handleClickMoneda}
                inputs={input.inputs}
                titleSection={input.titleSection}
                handleChangeFormCotizacion={handleChangeFormCotizacion}
                currencyMask={currencyMask}
                route={route}
                key={index}
              />
            );
          })}

          {route === "/cotizacion_directa_pdf"
            ? AnticiposDirecta.map((input, index) => {
                return (
                  <InputSection
                    id={input.id}
                    formCotizacion={formCotizacion}
                    buttonsPago={buttonsPago}
                    buttonsMoneda={buttonsMoneda}
                    handleClickPago={handleClickPago}
                    handleClickMoneda={handleClickMoneda}
                    inputs={input.inputs}
                    titleSection={input.titleSection}
                    handleChangeFormCotizacion={handleChangeFormCotizacion}
                    currencyMask={currencyMask}
                    route={route}
                    key={index}
                  />
                );
              })
            : route === "/cotizacion_inmobiliaria_pdf"
            ? ComsionesInmobiliaria.map((input, index) => {
                return (
                  <InputSection
                    id={input.id}
                    formCotizacion={formCotizacion}
                    buttonsPago={buttonsPago}
                    buttonsMoneda={buttonsMoneda}
                    handleClickPago={handleClickPago}
                    handleClickMoneda={handleClickMoneda}
                    inputs={input.inputs}
                    titleSection={input.titleSection}
                    handleChangeFormCotizacion={handleChangeFormCotizacion}
                    currencyMask={currencyMask}
                    route={route}
                    key={index}
                  />
                );
              })
            : null}
        </div>

        <div className={style.ContainerButtonsHeader}>
          <div className={style.Input}>
            <button
              className={style.ButtonCalcular}
              onClick={() => {
                handleCalcularGarantia();
              }}
              disabled={
                !formCotizacion.alquiler ||
                !formCotizacion.expensas ||
                !formCotizacion.duracion
              }
            >
              Calcular Garantía
            </button>
          </div>
        </div>

        <div className={style.ContainerButtonPDF}>
          <button
            onClick={() => generarPDF(formCotizacion.nombre, "containerPDF")}
            className={style.ButtonPDF}
          >
            Generar PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputsCotizaciones;
