import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const generarPDF = (nombre, elementID, route) => {
  console.log("nombre: ", nombre, "elementID: ", elementID);
  const input = document.getElementById(elementID);

  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

    if (route === "/recibo_pdf") {
      pdf.save(`Recibo ${nombre}`);
    } else {
      pdf.save(`Cotización ${nombre}`);
    }
  });
};

export default generarPDF;
