const objectCuotas = [
  [
    {
      titleSection: "Cantidad de Cuotas",
      id: "cantidadCuotas",
      inputs: [
        [
          {
            text: "N° de Cuotas",
            name: "cantidadCuotas",
          },
          {
            text: "Cantidad de Cuotas (letras)",
            name: "cantidadCuotasLetras",
          },
        ],
        {
          text: "Mes Vencimiento 1er Cuota",
          name: "mes",
        },
      ],
    },
  ],
];

export default objectCuotas;
