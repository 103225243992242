const objectLogin = [
    {
        title: 'Email',
        name: 'email',
        type: 'text',
    },
    {
        title: 'Password',
        name: 'pass',
        type: 'password'
    }
];

export default objectLogin;