const currencyMask = (e) => {
  let value = e.target.value;
  value = value.replace(/\D/g, "");
  value = value
    .toString()
    .split("")
    .reverse()
    .join("")
    .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
  value = value.split("").reverse().join("").replace(/^[.]/, "");
  e.target.value = value;
  return e;
};

export default currencyMask;