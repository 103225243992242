import React from "react";
import cuitMask from "../Controllers/cuitMask";
import style2 from "../../ReservaToPDF/styles/InputsReserva.module.css";

const Input = ({
  id,
  text,
  name,
  currencyMask,
  handleChangeFormCotizacion,
  route,
  clicked,
  setClicked,
  cuit,
  setCuit,
}) => {
  return (
    <div
      key={id}
      className={
        route === "/reservas_pdf" || route === "/recibo_pdf"
          ? style2.containerInputReserva
          : style2.containerInputCotizacion
      }
    >
      <label className={style2.titleInput}>{text}</label>
      {name === "observaciones" ? (
        <textarea
          className={style2.inputReserva}
          style={{ height: "120px", width: "390px" }}
          name={name}
          onChange={(e) => {
            handleChangeFormCotizacion(e);
          }}
        ></textarea>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <input
            className={
              route === "/reservas_pdf" || route === "/recibo_pdf"
                ? style2.inputReserva
                : style2.input
            }
            name={name}
            onInput={
              cuit && name === "dni"
                ? cuitMask
                : name === "alquiler" ||
                  name === "expensas" ||
                  name === "valorCuotasIncendio" ||
                  name === "reserva" ||
                  name === "dni" ||
                  name === "garantia" ||
                  name === "monto" ||
                  name === "total" ||
                  name === "nroContrato" ||
                  name === "dniCogarante1" ||
                  name === "dniCogarante2"
                ? currencyMask
                : null
            }
            onChange={(e) => {
              handleChangeFormCotizacion(e);
            }}
          ></input>
          {name === "monto" ? (
            <button
              style={{
                margin: "0px",
                padding: "0px",
                width: "75px",
                height: "36px",
                alignSelf: "center",
                backgroundColor: clicked && "#a0e838",
              }}
              className={style2.buttonPDF}
              onClick={() => setClicked(!clicked)}
            >
              + IVA
            </button>
          ) : name === "dni" ? (
            <button
              style={{
                margin: "0px",
                padding: "0px",
                width: "75px",
                height: "36px",
                alignSelf: "center",
                backgroundColor: cuit && "#a0e838",
              }}
              className={style2.buttonPDF}
              onClick={() => setCuit(!cuit)}
            >
              CUIT
            </button>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Input;
