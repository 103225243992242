const objectDocumento = [
  {
    text: "Nombre y Apellido:",
    name: "nombreApellido",
  },
  [
    {
      text: "DNI:",
      name: "dni",
    },
    {
      text: "Contacto:",
      name: "contacto",
    },
  ],
  [
    {
      text: "Modo de pago:",
      name: "pago",
    },
    {
      text: "Cuota N°:",
      name: "numCuota",
      cuota: true,
    },
  ],
];

export default objectDocumento;
