import React from "react";
import style from "./styles/ReciboToPDF.module.css";

const ReciboField = ({ text, value, cuota, totalCuotas }) => {
  return (
    <div className={style.RowContainers}>
      <div className={style.Container}>
        {text}
        <div className={style.input}>{value}</div>
        {cuota && (
          <div style={{ display: "flex", gap: "20px" }}>
            <div> de </div>
            <div>{totalCuotas}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReciboField;
