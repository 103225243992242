import React from "react";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../actions";
import style from "./styles/ProfileAsesor.module.css";
import { useNavigate } from "react-router-dom";

const ButtonLogOut = ({ data }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogOut = () => {
    dispatch(logOutUser(data));

    navigate("/login");
  };

  return (
    <div style={{ alignSelf: "center" }}>
      <button
        className={style.ButtonCerrarSesion}
        onClick={() => handleLogOut()}
      >
        Cerrar Sesión
      </button>
    </div>
  );
};

export default ButtonLogOut;
