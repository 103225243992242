import { useEffect } from "react";
import { Image } from "react-bootstrap";
import style from "./styles/ModalCalculador.module.css";
import { useState } from "react";
import {
  star,
  percentage,
  frame,
  flechaModal,
  flechaModalBlue,
} from "./styles/assets/index";
import Dropdown from "./Dropdown";
import Swal from "sweetalert2";
import cuotas from "./CardsCuotas";
import CustomCuota from "./CustomCuota/CustomCuota";
import { SideBar } from "../SideBar/SideBar";
import { Button } from "rsuite";

export default function ModalCalculador({
  principal,
  ref,
  id,
  setRenderForm,
}) {
  const options = [
    "",
    "12 MESES",
    "24 MESES",
    "36 MESES",
    "48 MESES",
    "60 MESES",
  ];
  const optionsTypeInmueble = ["Vivienda", "Comercio", "Campo"];

  const [mobile, setMobile] = useState(false);
  const [mobile2, setMobile2] = useState(false);
  const [informacionStep1, setInformacionStep1] = useState();
  const [activeItem, setActiveItem] = useState(null);
  const [contado, setContado] = useState(null);
  const [anticipo3, setAnticipo3] = useState(null);
  const [anticipo6, setAnticipo6] = useState(null);
  const [tresCuotas, setTresCuotas] = useState(null);
  const [seisCuotas, setSeisCuotas] = useState(null);
  const [colorValorAlquiler, setColorValorAlquiler] = useState("none");
  const [colorValorExpensas, setColorValorExpensas] = useState("none");
  const [colorTipoDePropiedad, setColorTipoDePropiedad] = useState("none");
  const [colorDuracion, setColorDuracion] = useState("none");
  const [valido, setValido] = useState(false);
  const [resultado, setResultado] = useState("");

  const [form, setForm] = useState({
    valorAlquiler: "",
    valorExpensas: "",
    tipoDePropiedad: "",
    duracion: "",
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window?.innerWidth < 1200) {
        setMobile(true);
      }
      if (window?.innerWidth < 500) {
        console.log("mobile2: ", window.innerWidth);
        setMobile2(true);
      }
    }
  }, []);

  const calcularGarantia = (data) => {
    const { valorAlquiler, valorExpensas, duracion } = data;
    const alquiler = parseFloat(valorAlquiler.replace(/\./g, "") || 0);
    const expensas = parseFloat(valorExpensas.replace(/\./g, "") || 0);
    const duracionaux = parseInt(duracion);
    const resultado = (alquiler + expensas) * duracionaux * 0.08;
    return resultado;
  };

  const handleFormat = (e) => {
    var num = e.replace(/\.|[A-Za-z]/g, "");
    if (!isNaN(num)) {
      num = num
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      num = num.split("").reverse().join("").replace(/^[\.]/, "");
      e = num;
      return e;
    } else {
      e = e.replace(/[^\d\.]*/g, "");
      return e;
    }
  };
  const currencyMask = (e) => {
    let value = e;
    value = value.replace(/\D/g, "");
    value = value
      .toString()
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
    value = value.split("").reverse().join("").replace(/^[.]/, "");
    e = value;
    e = handleFormat(e);

    return e;
  };

  function formatNumber(resultado) {
    return new Intl.NumberFormat("es-ES", { useGrouping: false }).format(
      resultado
    );
  }
  const customStyle = {
    confirmButtonColor: "#004993",
    fontFamily: "Poppins, sans-serif",
  };
  const verificarCampos = async () => {
    setColorValorAlquiler(form.valorAlquiler.trim() !== "" ? "#F9FAFB" : "red");
    setColorValorExpensas(form.valorExpensas.trim() !== "" ? "#F9FAFB" : "red");
    setColorTipoDePropiedad(
      form.tipoDePropiedad.trim() !== "" ? "#F9FAFB" : "red"
    );
    setColorDuracion(form.duracion.trim() !== "" ? "#F9FAFB" : "red");
  };
  const onSubmit = async (data) => {
    setValido(true);
    await verificarCampos();
    if (
      !data.valorAlquiler ||
      !data.valorExpensas ||
      !data.duracion ||
      !data.tipoDePropiedad
    ) {
      Swal.fire({
        text: "Debe completar todos los campos",
        icon: "error",
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "mi-clase-confirm",
        },
        ...customStyle,
      });
    } else {
      const resultado = Math.floor(calcularGarantia(data));
      setResultado(formatNumber(resultado));
      const anticipo3Cuotas = Math.floor(resultado * 0.3);
      setAnticipo3(formatNumber(anticipo3Cuotas));
      const interesMensual = resultado * 0.48; //interés de 8% mensual en la cotización en 6 cuotas
      const garantia6Cuotas = resultado + interesMensual;
      const anticipo6Cuotas = Math.floor(garantia6Cuotas * 0.15);
      setAnticipo6(formatNumber(anticipo6Cuotas));
      // const contado = Math.floor(resultado - anticipo);
      const contado = Math.floor(resultado * 0.8); // descuento 20% al contado durante marzo
      setContado(formatNumber(contado));
      const tresCuotas = Math.floor((resultado - anticipo3Cuotas) / 2);
      setTresCuotas(formatNumber(tresCuotas));
      const seisCuotas = Math.floor((garantia6Cuotas - anticipo6Cuotas) / 5);
      setSeisCuotas(formatNumber(seisCuotas));

      setInformacionStep1(data);

      //   dispatch(setResultadoCalculador(true));
      setValido(false);
    }
  };

  const handleChangeForm = async (e) => {
    let { name, value } = e.target;

    if (name === "valorAlquiler" || name === "valorExpensas") {
      value = currencyMask(value);
    }
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSelect = (selectedOption) => {
    setForm({
      ...form,
      duracion: selectedOption,
    });
    // Puedes manejar la selección aquí según tus necesidades
  };
  const handleSelectTypeProp = (selectedOption) => {
    setForm({
      ...form,
      tipoDePropiedad: selectedOption,
    });
    // Puedes manejar la selección aquí según tus necesidades
  };

  useEffect(() => {
    if (valido) {
      verificarCampos();
    }
  }, [form, valido]);

  const renderizarForm = () => {
    if (mobile) {
      const scrollAmount = 380; // por ejemplo, 200 píxeles

      window.scrollTo({
        top: 150,
        behavior: "smooth",
      });
      setRenderForm(true);
    } else {
      setRenderForm(true);
    }
  };

  return (
    <div style={{ display: 'flex', width: '-webkit-fill-available' }}>
      <SideBar />

      <form
        style={{
          width: mobile2 ? "90%" : principal ? "60%" : "100%",
          position: 'relative',
        }}
        className={style.ContainerModalCalculador}
        ref={ref}
        id={id}
      >
        <div className={style.ContainerAux}>
          <div className={style.TitleCalculador}>
            <div className={style.Title}>Calculá tu garantía</div>
            <div className={style.subTextCalculador}>
              Calculá al instante tu garantía y conocé las modalidades de pago
              que mejor se adapten a tus necesidades.{" "}
            </div>
          </div>

          <div className={style.ContenedorInputsForm}>
            <div className={style.ContainerValores}>
              <input
                style={{
                  border: `solid 1px ${colorValorAlquiler}`,
                }}
                autoComplete="off"
                type="text"
                name="valorAlquiler"
                className={style.input1}
                placeholder="Valor de alquiler"
                value={form.valorAlquiler}
                onChange={handleChangeForm}
              />
              <div className={style.lineaAzul}></div>
              <input
                style={{
                  border: `solid 1px ${colorValorExpensas}`,
                }}
                autoComplete="off"
                type="text"
                name="valorExpensas"
                className={style.input2}
                value={form.valorExpensas}
                placeholder="Valor de expensas"
                onChange={handleChangeForm}
              />
            </div>
            <div className={style.ContainerValores}>
              <Dropdown
                colorTipoDePropiedad={colorTipoDePropiedad}
                options={optionsTypeInmueble}
                onSelect={handleSelectTypeProp}
                placeholder={"Tipo"}
              />

              <div className={style.lineaAzul}></div>
              <Dropdown
                colorDuracion={colorDuracion}
                options={options}
                onSelect={handleSelect}
                placeholder={"Duración"}
              />
            </div>
            <Button
              onClick={() => onSubmit(form)}
              className={style.flechaCalcula}
            >
              {mobile ? (
                <div className={style.textoCalculador}>Calcular</div>
              ) : (
                <Image src={flechaModal} alt="flecha" />
              )}
            </Button>
          </div>
          {/* {!mobile ? (
            <div className={style.ContainerPasos}>
              <div className={style.SolicitalaText}>
                Solicitala en pocos pasos
              </div>
              <div className={style.ContentPublicidadLineWhite}>
                <div className={style.ContentPublicidad}>
                  <div className={style.contentIconPubli}>
                    <Image src={percentage} alt="porcentaje" />
                    <div className={style.textPaso}>Cotizá tu garantía</div>
                    <div className={style.bolitaBlanca}></div>
                  </div>

                  <div className={style.contentIconPubli}>
                    <Image src={frame} alt="datos" />
                    <div className={style.textPaso}>Validá tus datos</div>
                    <div className={style.bolitaBlanca}></div>
                  </div>
                  <div className={style.contentIconPubli}>
                    <Image src={star} alt="estrella" />
                    <div className={style.textPaso}>¡Listo!</div>
                    <div className={style.bolitaBlanca}></div>
                  </div>
                </div>
              </div>
            </div>
          ) : null} */}

          {resultado &&
          anticipo3 &&
          anticipo6 &&
          contado &&
          tresCuotas &&
          seisCuotas ? (
            <div className={style.ExtraContainerCards}>
              <div className={style.ContainerCards}>
                {cuotas?.map((c) => {
                  return (
                    <CustomCuota
                      key={c.id}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                      tipo={c.tipo}
                      cardTitle={c.title}
                      subTitleCard={c.subTitle}
                      cuotas={c.pagos}
                      valorAnticipo3={currencyMask(anticipo3)}
                      valorAnticipo6={currencyMask(anticipo6)}
                      valorCuota={
                        c.cuotas === 1
                          ? currencyMask(contado)
                          : c.cuotas === 3
                          ? currencyMask(tresCuotas)
                          : currencyMask(seisCuotas)
                      }
                      resultado={currencyMask(resultado)}
                    />
                  );
                })}
              </div>

              <div className={style.textDisclaimer}>
                * Descuento de contado aplicable sólo a residentes con dni
                argentino permanente en compras directas a Trust Fund casa
                central solicitadas a través de este calculador.
              </div>

              {/* <div className={style.containerButtonCard}>
                <div
                  onClick={() => renderizarForm()}
                  className={style.buttonSolicitarDisabled}
                >
                  Solicitá tu garantía
                </div>
                {!mobile && (
                  <div className={style.contFlechaSolicitar}>
                    <Image
                      onClick={() => renderizarForm()}
                      alt="flecha"
                      className={style.flechaSolicitar}
                      src={flechaModalBlue}
                    />
                  </div>
                )}
              </div> */}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}
