import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import "bootstrap/dist/css/bootstrap.min.css";

const TablePagination = ({
  totalItems,
  activePage,
  setActivePage,
  busqueda,
  responsables,
}) => {

  useEffect(() => {
    if(busqueda || responsables){
      setActivePage(1);
    }
  }, [busqueda, responsables])

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderPaginationItems = () => {
    let items = [];

    if (totalItems <= 10) {
      for (let number = 1; number <= totalItems; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === activePage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      if (activePage <= totalItems - 3) {
        for (
          let number = activePage;
          number <= activePage + 2 && number <= totalItems - 3;
          number++
        ) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === activePage}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
      }

      if (activePage < totalItems - 2) {
        items.push(<Pagination.Ellipsis key="ellipsis2" />);
      }

      if (activePage <= totalItems) {
        for (let number = totalItems - 2; number <= totalItems; number++) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === activePage}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </Pagination.Item>
          );
        }
      }
    }

    return items;
  };

  return (
    <Pagination>
      <Pagination.First
        onClick={() => handlePageChange(1)}
        disabled={activePage === 1}
      />
      <Pagination.Prev
        onClick={() => handlePageChange(activePage - 1)}
        disabled={activePage === 1}
      />
      {renderPaginationItems()}
      <Pagination.Next
        onClick={() => handlePageChange(activePage + 1)}
        disabled={activePage === totalItems}
      />
      <Pagination.Last
        onClick={() => handlePageChange(totalItems)}
        disabled={activePage === totalItems}
      />
    </Pagination>
  );
};

export default TablePagination;
