import React from "react";
import Login from "./Login/Login";
import { LogoTF } from "../DocumentosPDF/ReciboToPDF/styles/assets";
import style from "./styles/Landing.module.css";

export const Landing = () => {
  return (
    <div className={style.LoginContainer}>
      <div className={style.titleCRM}>
        Bienvenido al CRM de
        <img className="logoTrust" alt="logoTrustFund" src={LogoTF} />
      </div>
      <Login />
    </div>
  );
};
