import React from "react";
import style from "./styles/ContratoToPDF.module.css";

const ModalLoading = () => {
  return (
    <div className={style.modalLoading}>
      <div style={{ fontSize: "24px", color: "#004994", fontWeight: "700" }}>
        Generando PDF...
      </div>
      <div style={{ fontSize: "16px", fontWeight: "400" }}>
        Por favor, espere.
      </div>
    </div>
  );
};

export default ModalLoading;
