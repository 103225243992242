const headers = [
    { label: "Nombre Inmobiliaria", key: "nombre", style: { width: '400px', height: '500px' } },
    { label: "Asesor/a", key: "asesor", style: { width: '400px', height: '500px' } },
    { label: "Ventas", key: "ventas", style: { width: '400px', height: '500px' } },
    { label: "Telefono", key: "telefono", style: { width: '400px', height: '500px' } },
    { label: "Provincia", key: "provincia", style: { width: '400px', height: '500px' } },
    { label: "Localidad", key: "localidad", style: { width: '400px', height: '500px' } },
    { label: "Codigo Postal", key: "codPostal", style: { width: '400px', height: '500px' } },
    { label: "Domicilio", key: "domicilio", style: { width: '400px', height: '500px' } },
    { label: "Matrícula", key: "matricula", style: { width: '400px', height: '500px' } },
    { label: "CUIT", key: "cuit", style: { width: '400px', height: '500px' } },
    { label: "E-mail", key: "mail", style: { width: '400px', height: '500px' } },
    { label: "web", key: "web", style: { width: '400px', height: '500px' } },
];

export default headers;