import React from "react";
import style from "../styles/CotizacionDirectaToPDF.module.css";

const ButtonsInputs = ({text, value, handleClick}) => {

  return (
    <div className={style.InputRow}>
      <button
        className={style.Button}
        style={{
          backgroundColor: `${
            value === text ? "#a0e838" : "#004994"
          }`,
        }}
        onClick={() => {handleClick(text);}}>
        {text}
      </button>
    </div>
  );
};

export default ButtonsInputs;
