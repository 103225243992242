import React, { useState } from "react";
import { Modal, Button } from "rsuite";
import {
  StyledForm,
  UserDetails,
  InputBox,
  Details,
  InputTag,
  Title,
  ModalBtn,
  EditBoxAgregar,
} from "../styledComponents/styledSolicitud";
import { Hero, Hero1 } from "../styledComponents/styledInmobiliariasAdheridas";
import { agregarInmobiliaria, getInmobiliarias } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import InputsForm from "./InputsForm";
import Swal from "sweetalert2";
import styles from "./styles/InmobiliariasAdheridas.module.css";

const cloudName = process.env.REACT_APP_CLOUD_NAME;
const apiKey = process.env.REACT_APP_API_KEY;
const uploadPreset = process.env.REACT_APP_UPLOAD_PRESET;

export const ModalAgregarInmobiliaria = ({
  setModalAgregarInmob,
  setRender,
  render,
  inmobiliaria,
  user,
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  // const [img, setImg] = useState(null);
  const [validation, setValidation] = useState(true);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [input, setInput] = useState({
    nombre: "",
    asesorInmo: "",
    telefono: "",
    asesorTF: "",
    provincia: "",
    localidad: "",
    domicilio: "",
    web: "",
    mail: "",
    comentario: "",
    logo: "",
  });

  const [logoInmo, setLogoInmo] = useState({
    logoInmo: "",
  });

  const error = useSelector((state) => state.error);

  const handleFiles = async (e) => {
    if (e.target.name === "logoInmo") {
      const files = e.target.files;
      const data = new FormData();
      data.append("resource_type", "raw");
      data.append("file", files[0]);
      data.append("upload_preset", "ksyblkmu");
      data.append("api_key", apiKey);
      const res = await fetch(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        {
          method: "POST",
          body: data,
        }
      );

      if (res.status === 200) {
        const file = await res.json();
        setLogoInmo({ [e.target.name]: file.secure_url });
        setInput({
          ...input,
          logo: file.secure_url,
        });
      } else {
        console.log("error al subir al Cloudinary", res);
      }
    }
  };

  const handleFile = (e) => {
    e.preventDefault();
    handleFiles(e);
  };

  const handleValidation = () => {
    if (input.nombre.length > 0 && input.asesorTF.length > 0) {
      setValidation(false);
    }
  };

  const handleChangeInput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });

    handleValidation();
  };

  const handleEnviar = () => {
    const logo = logoInmo.logoInmo;

    if (
      inmobiliaria.some(
        (obj) =>
          Object.entries(obj).some(
            ([key, value]) => key === "nombre" && value === `${input.nombre}`
          ) &&
          Object.entries(obj).some(
            ([key, value]) =>
              key === "asesorInmo" && value === `${input.asesorInmo}`
          )
      )
    ) {
      Swal.fire({
        text: "Ya existe un asesor con ese nombre en esa Inmobiliaria.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    } else {
      dispatch(agregarInmobiliaria(input));

      setTimeout(() => {
        dispatch(getInmobiliarias());
      }, 1500);

      if (!error) {
        setShowModalConfirm(true);
        setOpen(false);
        setModalAgregarInmob(false);
        setRender(!render);
      }
    }
  };

  const handleClose = () => {
    // setImg(null)
    setOpen(null);
    setModalAgregarInmob(false);
    setRender(!render);
  };

  return (
    <>
      {open && (
        <div className="modal-container">
          <Modal size={"lg"} open={open} onClose={handleClose}>
            <Modal.Header>
              <ModalBtn>
                <Hero>
                  <Hero1 style={{ margin: "auto" }}>
                    <Title>Datos de la Inmobiliaria</Title>
                  </Hero1>
                </Hero>
              </ModalBtn>
            </Modal.Header>
            <Modal.Body>
              <StyledForm>
                <div
                  className={styles.SubmitContainer}
                  style={{ display: "none" }}
                >
                  <EditBoxAgregar>
                    <div type="submit" className={styles.containerButtonLogo}>
                      <label htmlFor="files" className={styles.labelLogo}>
                        Subir Logo Inmobiliaria
                      </label>
                      <input
                        id="files"
                        name="logoInmo"
                        style={{ visibility: "hidden" }}
                        type="file"
                        onChange={handleFile}
                      />
                    </div>
                  </EditBoxAgregar>

                  {logoInmo.logoInmo !== "" && (
                    <img
                      className="iconUserTableMain"
                      style={{
                        borderRadius: "50%",
                        height: "100px",
                        width: "100px",
                      }}
                      src={logoInmo.logoInmo}
                    />
                  )}
                </div>

                <UserDetails>
                  {InputsForm?.map((input, index) => {
                    if (input.option) {
                      return (
                        <InputBox key={index}>
                          <Details>{input.title}</Details>
                          <Button className={styles.ButtonFormSelect}>
                            <select
                              name={input.inputName}
                              onChange={(e) => handleChangeInput(e)}
                              className={styles.FormSelect}
                            >
                              {input.option?.map((op, index) => {
                                return <option key={index} value={op}>{op}</option>;
                              })}
                            </select>
                          </Button>
                        </InputBox>
                      );
                    } else {
                      return (
                        <InputBox>
                          <Details>{input.title}</Details>
                          <InputTag
                            name={input.inputName}
                            required={input.required}
                            onChange={(e) => handleChangeInput(e)}
                          ></InputTag>
                        </InputBox>
                      );
                    }
                  })}
                </UserDetails>
              </StyledForm>
            </Modal.Body>
            <Modal.Footer style={{ padding: "20px 35px 0px 0px" }}>
              <Button
                type="submit"
                disabled={validation ? true : false}
                className={styles.Button}
                onClick={() => handleEnviar()}
              >
                Agregar
              </Button>
              <Button
                className={styles.Button}
                onClick={handleClose}
                color="red"
                appearance="primary"
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};
