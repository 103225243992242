const objectContrato = [
  [
    {
      titleSection: "Fecha",
      id: "Fecha",
      inputs: [
        [
          {
            text: "Día Actual",
            name: "diaInput",
          },
          {
            text: "Mes Actual",
            name: "mesInput",
          },
        ],
        [
          {
            text: "Año Actual",
            name: "añoInput",
          },
          {
            text: "Nro. Contrato",
            name: "nroContrato",
          },
        ],
      ],
    },
    {
      titleSection: "Datos Solicitante",
      id: "datosPersonales",
      inputs: [
        [
          {
            text: "Nombre/Apellido",
            name: "nombreApellido",
          },
          {
            text: "DNI",
            name: "dni",
          },
        ],
        [
          {
            text: "Domicilio",
            name: "domicilio",
          },
          {
            text: "Mail",
            name: "mail",
          },
        ],
      ],
    },
  ],
  [
    {
      titleSection: "Datos Contrato",
      id: "datosContrato",
      inputs: [
        [
          {
            text: "Calle Inmueble",
            name: "calle",
          },
          {
            text: "Garantía",
            name: "garantia",
          },
        ],
        [
          {
            text: "Porcentaje 1er Cuota",
            name: "porcentajePrimerCuota",
          },
          {
            text: "Cantidad Meses Vigencia",
            name: "mesesVigencia",
          },
        ],
      ],
    },
    {
      titleSection: "Valores Alquiler",
      id: "valoresAlquiler",
      inputs: [
        [
          {
            text: "Alquiler",
            name: "alquiler",
          },
          {
            text: "Alquiler (letras)",
            name: "alquilerLetras",
          },
        ],
        {
          text: "Expensas",
          name: "expensas",
        },
      ],
    },
  ],
  [
    {
      titleSection: "Fecha",
      id: "FechaFirma",
      inputs: [
        [
          {
            text: "Día Firma",
            name: "diaFirma",
          },
          {
            text: "Mes Firma",
            name: "mesFirma",
          },
        ],
        {
          text: "Año Firma",
          name: "añoFirma",
        },
      ],
    },
  ],
];

export default objectContrato;
